import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';

import Status from '~/components/status/Status';
import { IWorkRequest } from 'sb_manufacturing_front_api';
import Risk from '~/components/status/Risk';
import { selectDepartments } from '~/redux/selectors/departmentsSelectors';

const WorkRequest = (item: IWorkRequest) => {
  const dispatch = useDispatch();
  const {} = item;
  const departments = useSelector(selectDepartments);
  const departmentTitle = departments.find(({ id }) => id === item.department_id)?.name;
  const risk =
    'classification_otipb_risk' in item
      ? item.classification_otipb_risk ?? item.classification_author_risk
      : undefined;
  const handleClick = React.useCallback(() => {
    dispatch(
      openPopup({
        name: POPUPS_NAME.VIEW_WORK_REQUEST_POPUP,
        data: {
          workRequest: item,
        },
      }),
    );
  }, [item]);

  return (
    <div className="application" onClick={handleClick}>
      <div className="application__header">
        <Status title={item.status} />
        {risk && <Risk riskCategory={risk} />}
      </div>
      <p className="application__title">{item.title}</p>
      <p className="application__tasks__quantity">
        {departmentTitle && departmentTitle && (
          <>
            <span className="application__tasks__department">{departmentTitle}</span>
            <pre> · </pre>
          </>
        )}
        {item.status !== 3 && item.status !== 2
          ? `${item?.tasks?.length || 0} задач`
          : `${item?.tasks?.filter(item => item.status === 2)?.length || 0} / ${
              item?.tasks?.length || 0
            } задач выполнено`}
      </p>
    </div>
  );
};

export default WorkRequest;

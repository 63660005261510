import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';

import { workRequestCustomFieldsAdapter } from '~/redux/modules/workRequestCustomFieldsModule';

export const workRequestCustomFieldsSelectors = workRequestCustomFieldsAdapter.getSelectors<IStore>(
  state => state.workRequestCustomFields,
);

export const selectWorkRequestCustomFields = createSelector(
  (state: IStore) => workRequestCustomFieldsSelectors.selectAll(state),
  workRequestCustomFields => workRequestCustomFields,
);

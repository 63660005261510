import * as React from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseSvg } from '~/assets/close.svg';
import { useDispatch } from 'react-redux';
import { closePopup } from '~/redux/modules/popupModule';

interface IPopup {
  closable?: boolean;
  aside?: boolean;
  children: React.ReactChild | React.ReactChild[];
  className?: string;
  onClose?: () => void;
}

const Popup = ({ closable = true, aside, children, className, onClose }: IPopup) => {
  const dispatch = useDispatch();

  return (
    <div
      className={classNames('popup', className, {
        popupAside: aside,
      })}
    >
      {children}
      {closable && (
        <div
          className="popupClose"
          onClick={() => {
            dispatch(closePopup());
            if (onClose) {
              onClose();
            }
          }}
        >
          <CloseSvg />
        </div>
      )}
    </div>
  );
};

export default Popup;

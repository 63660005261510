import { FC, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import {
  ETaskPriority,
  ETaskStatus,
  INewGeoItem,
  INewTask,
  ITaskTrack,
} from 'sb_manufacturing_front_api';
import { TaskInfo } from './task-info';
import convertToLocal from '~/helpers/convertToLocal';
import { useSelector } from 'react-redux';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { selectCurrentUserId } from '~/redux/selectors/authSelectors';
import { FORMAT_MOMENT } from '~/helpers/constants';
import PriorityIcon from '~/components/icon/PriorityIcon';
import { ReactComponent as ClockSvg } from '~/assets/clock.svg';
import { Tooltip } from 'antd';
import useShiftTime from '~/hooks/useShiftTime';
import { INewShift } from 'sb_manufacturing_front_api';

const HOUR_WIDTH = 300;
const PX_PER_SECOND = 96 / 60 / 60;

const STATUS_TO_BG = {
  [ETaskStatus.E_COMPLETE]: 'success',
  [ETaskStatus.E_REJECTED]: 'error',
  [ETaskStatus.E_EXPIRED]: 'error',
  [ETaskStatus.E_IN_PROGRESS]: 'progress',
  [ETaskStatus.E_VALIDATION]: 'default',
  [ETaskStatus.E_VALIDATION_SV]: 'default',
  [ETaskStatus.E_PAUSE]: 'default',
  [ETaskStatus.E_EMERGENCY_PAUSED]: 'default',
  [ETaskStatus.E_DEFAULT]: 'default',
  [ETaskStatus.E_REJECTED_SV]: 'error',
  [ETaskStatus.E_REJECTED_D]: 'error',
};

export interface ITaskOnTable {
  users: INewShift[];
  task: INewTask & { start_date?: string; end_date?: string; duration?: number };
  id: number;
  start?: string;
  user_id: number;
  order: number;
  end_time: string | null;
  parent_id: number | null;
  begin_time: string | null;
  title: string;
  read_only: boolean | undefined;
  type_id?: number;
  geo_type?: number;
  geo_status?: number;
  geo?: INewGeoItem[];
  description: string;
  priority: ETaskPriority;
  status?: ETaskStatus;
  is_comment?: number;
  need_photo_report_before_start?: boolean;
  need_photo_report_after_complete?: boolean;
  isEveryday?: boolean;
  taskCarouselRef?: RefObject<HTMLDivElement>;
  need_path_photo_report?: number;
  track?: ITaskTrack;
  unfixed?: boolean;
  key?: string;
  y?: number;
  height?: number;
  margin?: number;
}

export const TaskOnTable: FC<ITaskOnTable> = ({
  users,
  user_id,
  order,
  begin_time,
  end_time,
  track,
  title,
  type_id,
  geo_type,
  geo,
  geo_status,
  need_path_photo_report,
  priority = ETaskPriority.E_REGULAR,
  status = ETaskStatus.E_DEFAULT,
  taskCarouselRef,
  description,
  need_photo_report_before_start,
  need_photo_report_after_complete,
  read_only,
  task,
  margin,
  unfixed,
  ...rest
}) => {
  const [opened, setOpened] = useState<boolean>(false);
  const timeZone = useSelector(selectCompanyOptions).time_zone;
  const { beginTime } = useShiftTime();
  const convertStartTrack = moment((track && track?.start_time) || begin_time)
    .add(timeZone, 'hour')
    .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS);
  const realStartOrStartTime = (track && track?.start_time) || begin_time;
  const realEndOrEndTime = (track && track?.end_time) || end_time;
  const [positionX, positionY, height] = useMemo(() => {
    return [
      order * HOUR_WIDTH,
      (moment(convertStartTrack).unix() - moment(beginTime).unix()) * PX_PER_SECOND,
      (moment(realEndOrEndTime).unix() - moment(realStartOrStartTime).unix()) * PX_PER_SECOND,
    ];
  }, [order, beginTime, convertStartTrack, realEndOrEndTime]);

  const [boolPosLeft, setBoolPosLeft] = useState(false);
  const companyOptions = useSelector(selectCompanyOptions);
  const userId = useSelector(selectCurrentUserId);
  useEffect(() => {
    if (taskCarouselRef?.current) {
      positionX + HOUR_WIDTH * 3 > taskCarouselRef.current.scrollWidth
        ? setBoolPosLeft(true)
        : setBoolPosLeft(false);
    }
  }, [taskCarouselRef?.current]);

  const isMeShift = users.map(item => item.user_id).includes(userId);

  const time = useMemo(() => {
    const timeValue = (time: string) =>
      convertToLocal(moment(time).format(FORMAT_MOMENT.HHMM), false, companyOptions.time_zone);

    return `${timeValue(
      realStartOrStartTime || moment().subtract('hours', 3).format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
    )}-${timeValue(
      realEndOrEndTime || moment().subtract('hours', 3).format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
    )}`;
  }, [realStartOrStartTime, realEndOrEndTime]);

  const handleClose = useCallback(() => setOpened(false), []);

  const handleClick = useCallback(() => setOpened(true), []);

  const statusClass = useMemo(() => {
    let isStatusOk = true;
    const notCheckStatuses = [
      ETaskStatus.E_REJECTED,
      ETaskStatus.E_IN_PROGRESS,
      ETaskStatus.E_PAUSE,
      ETaskStatus.E_DEFAULT,
    ];
    if (geo && geo.length && !notCheckStatuses.includes(status)) {
      geo.forEach(item => {
        isStatusOk = isStatusOk && item.status;
      });
    }

    const bgStatus = isStatusOk ? status : ETaskStatus.E_REJECTED;

    return `task_status__${STATUS_TO_BG[bgStatus]}`;
  }, [status, geo]);

  const duration = moment(realEndOrEndTime).unix() - moment(realStartOrStartTime).unix();

  const left = !isMeShift ? positionX : (userId === user_id ? positionX : positionX + 48) || 0;

  return (
    <div
      className={cx('taskContainer', margin ? `lvl${margin}` : '', { active: opened })}
      style={{
        left: left,
        top: positionY > 0 ? positionY : 0,
        height,
        position: 'absolute',
        display: 'flex',
      }}
    >
      <div
        className={cx(
          'task',
          task.is_expired ? 'task_status__error' : statusClass,
          { 'task_task-info-active': opened },
          { 'task--priority': priority === ETaskPriority.E_EMERGENCY },
          duration / 60 <= 15 && 'task-min-small',
        )}
        onClick={handleClick}
      >
        {duration / 60 > 30 ? (
          <div className={'task__description-block'}>
            <div className="task__time">{time}</div>
            <div className="task__description">
              <span
                className={`task__name ${
                  [ETaskPriority.E_HIGH, ETaskPriority.E_EMERGENCY].includes(priority)
                    ? `priority${priority}`
                    : ''
                }`}
              >
                {[ETaskPriority.E_HIGH, ETaskPriority.E_EMERGENCY].includes(priority) && (
                  <PriorityIcon id={priority} />
                )}
                <span className={'task__name'}>{title}</span>
              </span>
              {!!description && description.trim() && (
                <span className="task__name">{description}</span>
              )}
            </div>
          </div>
        ) : (
          <>
            <span className="task__time task__time--very-small">{time}</span>
            <span
              className={`task__name task__name--very-small ${
                [ETaskPriority.E_HIGH, ETaskPriority.E_EMERGENCY].includes(priority)
                  ? `priority${priority}`
                  : ''
              }`}
            >
              {[ETaskPriority.E_HIGH, ETaskPriority.E_EMERGENCY].includes(priority) && (
                <PriorityIcon id={priority} />
              )}
              <span className={'task__name'}>{title}</span>
            </span>
          </>
        )}
        {unfixed && priority !== ETaskPriority.E_EMERGENCY && (
          <Tooltip placement="top" title="Задача вне расписания">
            <ClockSvg className="task__unfixedIcon" />
          </Tooltip>
        )}
        <TaskInfo
          task={task}
          read_only={read_only}
          user_id={user_id}
          onClose={handleClose}
          status={status}
          title={title}
          type_id={type_id}
          geo_type={geo_type}
          geo={geo}
          geo_status={geo_status}
          start={begin_time}
          end={end_time}
          show={opened}
          boolPosLeft={boolPosLeft}
          priority={priority}
          need_path_photo_report={need_path_photo_report}
          description={description}
          need_photo_report_before_start={need_photo_report_before_start}
          need_photo_report_after_complete={need_photo_report_after_complete}
          unfixed={unfixed}
          {...rest}
        />
      </div>
    </div>
  );
};

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import { ReactComponent as WarningCircle } from '~/assets/warning-circle-blue.svg';
import Status from '~/components/status/Status';
import { ERepairRequestStatus, ETaskStatus, IRepairRequest } from 'sb_manufacturing_front_api';
import classNames from 'classnames';
import { Tooltip } from 'antd';

const RepairRequest = (item: IRepairRequest) => {
  const dispatch = useDispatch();
  const { status } = item;

  const handleClick = React.useCallback(() => {
    dispatch(
      openPopup({
        name: POPUPS_NAME.VIEW_REPAIR_REQUEST_POPUP,
        data: {
          repairRequest: item,
        },
      }),
    );
  }, [item]);

  const totalTasks = item.tasks?.length || 0;

  const isCompletedStatus = [
    ERepairRequestStatus.E_CLOSED,
    ERepairRequestStatus.E_IN_PROGRESS,
  ].includes(item.status);

  const tasksIndicator = isCompletedStatus
    ? `${
        item.tasks?.filter(task => task.status === ETaskStatus.E_COMPLETE).length || 0
      } / ${totalTasks} задач выполнено`
    : `${totalTasks} задач`;

  return (
    <div
      className={classNames('application', { unPublished: !item.is_published })}
      onClick={handleClick}
    >
      <div className="warningSign">
        <Tooltip placement="top" title={'Снята с публикации'}>
          <WarningCircle />
        </Tooltip>
      </div>
      <div className="application__header">
        <Status title={status} />
      </div>
      <p className="application__title">{item.title}</p>
      <p className="application__tasks__quantity">{tasksIndicator}</p>
    </div>
  );
};

export default RepairRequest;

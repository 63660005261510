import { ReactNode, useEffect, useRef, useState } from 'react';
import Button from '~/components/form/buttons/Button';
import { BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';
import * as React from 'react';

interface IRowContentContainer {
  children?: ReactNode;
}

function getLineCount(element: HTMLElement) {
  const style = window.getComputedStyle(element);
  let lineHeight = parseFloat(style.lineHeight);

  if (isNaN(lineHeight)) {
    const fontSize = parseFloat(style.fontSize);
    lineHeight = fontSize * 1.2;
  }

  const totalHeight = element.getBoundingClientRect().height;
  return Math.round(totalHeight / lineHeight);
}

const RowContentContainer = ({ children }: IRowContentContainer) => {
  const [hide, setHide] = useState<boolean>(true);
  const [isHideButton, setIsHideButton] = useState<boolean>(true);
  const textRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (!textRef.current) return;
    const textRows = getLineCount(textRef.current);
    if (textRows && textRows > 2) {
      setIsHideButton(false);
    }
  }, [textRef]);
  return (
    <div className="row-content-container">
      <span
        ref={textRef}
        style={{
          WebkitLineClamp: !isHideButton && hide ? 2 : 'none',
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
          overflow: 'hidden',
        }}
      >
        {children}
      </span>
      {!isHideButton && (
        <Button
          type={BUTTON_TYPE.ICON}
          onClick={() => setHide(!hide)}
          className={'tabs__tab'}
          icon
          reverse
          text={hide ? 'Показать' : 'Скрыть'}
          iconType={hide ? ICON_TYPE.ARROW_BOTTOM : ICON_TYPE.ARROW_TOP}
        />
      )}
    </div>
  );
};

export default RowContentContainer;

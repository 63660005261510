import * as React from 'react';
import { FORMAT_MOMENT, TYPE_ROLE, ALERT, ALERT_TYPE } from '~/helpers/constants';
import { DECLINATION_TYPE } from '~/helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUserId, selectUserRole } from '~/redux/selectors/authSelectors';
import { Tooltip } from 'antd';
import { ETaskStatus } from 'sb_manufacturing_front_api';
import { selectUser } from '~/redux/selectors/usersSelectors';
import moment from 'moment';
import { FileInput } from '~/components';
import { ReactComponent as CloseSvg } from '~/assets/close.svg';
import { openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import { getLocalTimeFormat } from '~/helpers/convertToUnix';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { selectTask } from '~/redux/selectors/tasksSelectors';
import textDeclension from '~/helpers/textDeclension';
import { showAlert } from '~/redux/modules/stateModule';

interface SimplifiedEvent<T> {
  target: {
    value: T;
  };
}

interface ITaskNoTimeInside {
  taskId: number;
  duration?: number;
  startDate?: string;
  endDate?: string;
}

export const TaskNoTimeInside = ({ taskId, duration, startDate, endDate }: ITaskNoTimeInside) => {
  const dispatch = useDispatch();
  const task = useSelector(selectTask(taskId));
  const user = useSelector(selectUser(task?.user_id));
  const userRole = useSelector(selectUserRole);
  const userId = useSelector(selectCurrentUserId);
  const companyOptions = useSelector(selectCompanyOptions);

  const [openComment, setOpenComment] = React.useState(false);
  const [commentValue, setCommentValue] = React.useState<string>('');
  const [imageValue, setImageValue] = React.useState<string[]>([]);

  const durationNumber = () => {
    let duration = 0;
    if (task?.tracks) {
      task?.tracks?.map(item => {
        if (item.end_time && item.start_time) {
          duration = duration + moment(item.end_time).diff(moment(item.start_time));
        }
      });
    }
    return duration / 1000;
  };

  const hours: number = durationNumber() ? Math.floor(durationNumber() / 60 / 60) : 0;
  const minutes: number = durationNumber() ? Math.floor(durationNumber() / 60) - hours * 60 : 0;

  const onChangeComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentValue(event.target.value);
  };

  const textStatus = [
    'Назначена',
    'В работе',
    'Выполнено',
    'Отклонено',
    'Пауза',
    'Ожидает проверки',
    'Просрочено',
    'Ожидает проверки',
    'НС отклонил задачу',
    'НУ отклонил задачу',
  ];

  const classStatus = [
    'default',
    'working',
    'complete',
    'error',
    'pause',
    'expects',
    'error',
    'expects',
    'error',
    'error',
  ];

  const handleTaskStart = React.useCallback(
    (type?: string) => {
      const data = {
        title: 'Подтвердите действие',
        description: type === 'start' ? 'Начать задачу?' : 'Возобновить задачу?',
        name: ALERT.START_TASK,
        type: ALERT_TYPE.DEFAULT,
        data: {
          taskId: task?.id,
          taskStatus: ETaskStatus.E_IN_PROGRESS,
        },
      };

      dispatch(showAlert(data));
    },
    [task?.id],
  );

  const handleTaskEnd = React.useCallback(() => {
    const data = {
      title: 'Подтвердите действие',
      description: 'Завершить задачу?',
      name: ALERT.START_TASK,
      type: ALERT_TYPE.DEFAULT,
      data: {
        taskId: task?.id,
        taskStatus: ETaskStatus.E_COMPLETE,
      },
    };

    dispatch(showAlert(data));
  }, [task?.id]);

  const handleTaskPause = React.useCallback(async () => {
    const data = {
      title: 'Подтвердите действие',
      description: 'Завершить задачу?',
      name: ALERT.START_TASK,
      type: ALERT_TYPE.DEFAULT,
      data: {
        taskId: task?.id,
        taskStatus: ETaskStatus.E_PAUSE,
      },
    };

    dispatch(showAlert(data));
  }, [task?.id]);

  const handleMessage = React.useCallback(
    async (type: string) => {
      const data = {
        title: 'Подтвердите действие',
        description: type === 'start' ? 'Начать задачу?' : 'Завершить задачу?',
        name: ALERT.START_TASK,
        type: ALERT_TYPE.DEFAULT,
        data: {
          taskId: task?.id,
          taskStatus: type === 'start' ? ETaskStatus.E_IN_PROGRESS : ETaskStatus.E_COMPLETE,
          taskComment: commentValue,
          taskImages: imageValue,
        },
      };

      const timeOut = setTimeout(() => {
        dispatch(showAlert(data));
        clearTimeout(timeOut);
      }, 50);
    },
    [imageValue, commentValue],
  );

  const onFileInput = (event: SimplifiedEvent<string[]>) => {
    setImageValue(event.target.value);
  };

  const handleView = () => {
    dispatch(
      openPopup({
        name: POPUPS_NAME.VIEW_TASK_POPUP,
        data: {
          task: {
            ...task,
            end_date: endDate,
            start_date: startDate,
            duration: duration,
          },
        },
      }),
    );
  };

  return (
    <div className="task-no-time-inside">
      <div className="task-no-time-inside__header">
        <Tooltip placement="bottom" title={textStatus[task?.status]}>
          <div className={`task-no-time-inside__status ${classStatus[task?.status]}`} />
        </Tooltip>
        <div className="task-no-time-inside__name">
          {user ? `${user?.lastname} ${user?.firstname}` : 'Пользователь не найден'}
        </div>
        {[TYPE_ROLE.SUPERVISOR, TYPE_ROLE.DEPARTMENT].includes(userRole) &&
        [
          ETaskStatus.E_VALIDATION_SV,
          ETaskStatus.E_VALIDATION,
          ETaskStatus.E_REJECTED_SV,
          ETaskStatus.E_REJECTED_D,
          ETaskStatus.E_COMPLETE,
        ].includes(task?.status) ? (
          <div className="task-no-time-inside__view" onClick={handleView}>
            К проверке
          </div>
        ) : null}
      </div>
      <div className="task-no-time-inside__block">
        <div className="task-no-time-inside__item">
          <div className="task-no-time-inside__item-title">Фактическое время</div>
          <div className="task-no-time-inside__item-subtitle">
            {`${textDeclension(hours, DECLINATION_TYPE.HOURS)} ${textDeclension(
              minutes,
              DECLINATION_TYPE.MINUTES,
            )}`}
          </div>
        </div>
      </div>
      {task?.tracks?.length ? (
        <div className="task-no-time-inside__block">
          <div className="task-no-time-inside__item">
            <div className="task-no-time-inside__item-title">Время выполнения</div>
            {task?.tracks?.map(item => {
              const dateStart = getLocalTimeFormat(
                item.start_time,
                companyOptions.time_zone,
                FORMAT_MOMENT.DDMMYYYY,
              );
              const timeStart = getLocalTimeFormat(
                item.start_time,
                companyOptions.time_zone,
                FORMAT_MOMENT.HHMM,
              );
              const timeEnd = getLocalTimeFormat(
                item.end_time,
                companyOptions.time_zone,
                FORMAT_MOMENT.HHMM,
              );

              return (
                <div className="task-no-time-inside__item-subtitle">
                  {dateStart} · {timeStart ? timeStart : '–:–'} - {timeEnd ? timeEnd : '–:–'}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      {task?.logs.length ? (
        <div className="task-no-time-inside__block">
          <div className="task-no-time-inside__item">
            <div className="task-no-time-inside__item-title">Коментарии</div>
            {task?.logs.map((item, key) => (
              <div className="task-no-time-inside__item-subtitle" key={key}>
                {getLocalTimeFormat(item.date, companyOptions.time_zone, FORMAT_MOMENT.HHMM)}
                {' · '}
                {item.description ? item.description : item.title}
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {[TYPE_ROLE.SUPERVISOR].includes(userRole) && userId === task?.user_id ? (
        <div className="task-no-time-inside__start">
          {task?.need_photo_report_before_start || task?.geo_type ? (
            <div className="task-no-time-inside__start-mobile">
              Задачу требуется выполнять в приложении
            </div>
          ) : (
            <>
              {task?.status === ETaskStatus.E_DEFAULT ? (
                <div className="task-no-time-inside__buttons">
                  <div className="button" onClick={() => handleTaskStart('start')}>
                    начать задачу
                  </div>
                </div>
              ) : null}
              {task?.status === ETaskStatus.E_IN_PROGRESS ? (
                <>
                  {task?.is_comment ? (
                    <>
                      <div className="task-no-time-inside__buttons">
                        <div className="button button_blue" onClick={handleTaskPause}>
                          пауза
                        </div>
                        <div className="button" onClick={() => setOpenComment(true)}>
                          завершить задачу
                        </div>
                      </div>
                      {openComment ? (
                        <div className={`task-message ${openComment ? 'open' : ''}`}>
                          <div className="task-message__container">
                            <div
                              className="task-message__close"
                              onClick={() => setOpenComment(false)}
                            >
                              <CloseSvg />
                            </div>
                            <div className="task-message__content">
                              <div className="task-message__title">Завершить задачу</div>
                              <textarea
                                className="textarea textarea_gray"
                                value={commentValue}
                                placeholder="Введите комментарий"
                                onChange={onChangeComment}
                              />
                              <FileInput value={imageValue} onChange={onFileInput} />
                              <div className="task-message__inner">
                                <div
                                  className="task-message__button button"
                                  onClick={() => handleMessage('end')}
                                >
                                  завершить
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div className="task-no-time-inside__buttons">
                      <div className="button button_blue" onClick={handleTaskPause}>
                        пауза
                      </div>
                      <div className="button" onClick={handleTaskEnd}>
                        завершить задачу
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {task?.status === ETaskStatus.E_PAUSE ? (
                <>
                  {task?.is_comment ? (
                    <>
                      <div className="task-no-time-inside__buttons">
                        <div
                          className="button button_blue"
                          onClick={() => handleTaskStart('pause')}
                        >
                          возобновить
                        </div>
                        <div className="button" onClick={() => setOpenComment(true)}>
                          завершить задачу
                        </div>
                      </div>
                      {openComment ? (
                        <div className={`task-message ${openComment ? 'open' : ''}`}>
                          <div className="task-message__container">
                            <div
                              className="task-message__close"
                              onClick={() => setOpenComment(false)}
                            >
                              <CloseSvg />
                            </div>
                            <div className="task-message__content">
                              <div className="task-message__title">Завершить задачу</div>
                              <textarea
                                className="textarea textarea_gray"
                                value={commentValue}
                                placeholder="Введите комментарий"
                                onChange={onChangeComment}
                              />
                              <FileInput value={imageValue} onChange={onFileInput} />
                              <div className="task-message__inner">
                                <div
                                  className="task-message__button button"
                                  onClick={() => handleMessage('end')}
                                >
                                  завершить
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div className="task-no-time-inside__buttons">
                      <div className="button button_blue" onClick={() => handleTaskStart('pause')}>
                        возобновить
                      </div>
                      <div className="button" onClick={handleTaskEnd}>
                        завершить задачу
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

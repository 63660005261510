import * as React from 'react';
import { ReactComponent as BellSvg } from '~/assets/bell.svg';
import { ReactComponent as CalendarUser } from '~/assets/calendarUser.svg';
import { ReactComponent as WarningCircle } from '~/assets/warning-circle.svg';
import { ReactComponent as Dinner } from '~/assets/dinner.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotifications } from '~/redux/selectors/notificationSelectors';
import { selectUser } from '~/redux/selectors/usersSelectors';
import { selectCurrentUserId, selectUserRole } from '~/redux/selectors/authSelectors';
import { readAllNotifications } from '~/redux/modules/notificationsModule';
import { UserMenuShift } from './UserMenuShift';
import { selectAdditionalShifts } from '~/redux/selectors/additionalShiftsSelector';
import { getAdditionalShifts } from '~/redux/modules/additionalShiftsModule';
import { UserMenuTask } from './userMenuTask';
import { diffDateDaysNow } from '~/utils';
import { FORMAT_MOMENT, PASSWORD, TYPE_ROLE, DECLINATION_TYPE } from '~/helpers/constants';
import { openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import { selectDayDinners } from '~/redux/selectors/shiftsSelectors';
import moment from 'moment';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import textDeclension from '~/helpers/textDeclension';

export const UserMenu = () => {
  const currentUserId = useSelector(selectCurrentUserId);
  const userRole = useSelector(selectUserRole);
  const profile = useSelector(selectUser(currentUserId));
  const notifications = useSelector(selectNotifications);
  const additionalShifts = useSelector(selectAdditionalShifts);
  const dinners = useSelector(selectDayDinners);
  const timeZone = useSelector(selectCompanyOptions).time_zone;
  const [unreaded, setUnreaded] = React.useState(false);
  const [newSmena, setNewSmena] = React.useState(false);
  const dispatch = useDispatch();

  const myDinner = dinners.find(item => item.userId === currentUserId);
  const timeStart = moment(myDinner?.dinnerStartTime, FORMAT_MOMENT.HHMMSS);
  const timeEnd = moment(myDinner?.dinnerEndTime, FORMAT_MOMENT.HHMMSS);

  const startDinner = timeStart.add(timeZone, 'hours').format(FORMAT_MOMENT.HHMM);
  const endDinner = timeEnd.add(timeZone, 'hours').format(FORMAT_MOMENT.HHMM);

  const initials = profile
    ? profile?.lastname?.substring(0, 1) + profile?.firstname?.substring(0, 1)
    : '';

  const handleClickProfile = React.useCallback(() => {
    if (profile) {
      dispatch(openPopup({ name: POPUPS_NAME.WORKER_POPUP, data: { userId: profile.id } }));
    }
  }, [profile]);

  React.useEffect(() => {
    if (notifications) {
      const unreadNotifications = notifications.filter(notification => !notification.is_read);
      setUnreaded(unreadNotifications.length > 0);
    } else {
      setUnreaded(false);
    }
  }, [notifications]);

  React.useEffect(() => {
    if (additionalShifts) {
      setNewSmena(additionalShifts.length > 0);
    } else {
      setNewSmena(false);
    }
  }, [additionalShifts]);

  const openPopupNotifications = () => {
    dispatch(openPopup({ name: POPUPS_NAME.NOTIFICATIONS_POPUP }));
    setTimeout(() => {
      if (profile) {
        dispatch(readAllNotifications());
      }
    }, 2000);
  };

  const openExtraSmena = () => {
    dispatch(openPopup({ name: POPUPS_NAME.EXTRA_SHIFT_POPUP }));
    dispatch(getAdditionalShifts());
  };

  const openSetPassword = () => {
    dispatch(
      openPopup({
        name: POPUPS_NAME.SET_PASSWORD_POPUP,
        isAside: false,
        data: { type: PASSWORD.CHANGE },
      }),
    );
  };

  const diffDateBeforePasswordChange = diffDateDaysNow(profile?.last_password_change_at || '');
  const daysBeforePasswordChange = 365 - diffDateBeforePasswordChange;

  const [timeLeft, setTimeLeft] = React.useState(moment());

  React.useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(moment());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const dinnerActive =
    timeStart.diff(timeLeft, 'minute') <= 30 && timeEnd.diff(timeLeft, 'minute') >= 0;

  return (
    <div className="user-menu">
      {myDinner && myDinner.hasDinner && dinnerActive && (
        <div className="user-menu__dinner">
          <div className="dinner">
            <Dinner />
            <p>
              У вас обед с {startDinner} до {endDinner}
            </p>
          </div>
        </div>
      )}
      {diffDateBeforePasswordChange > 334 && diffDateBeforePasswordChange < 365 && (
        <div className="user-menu__needChangePassword">
          <div className="needChangePassword__content">
            <WarningCircle /> Необходимо сменить пароль в течении{' '}
            {textDeclension(daysBeforePasswordChange, DECLINATION_TYPE.DAYS)}.
            <span onClick={openSetPassword}>Сменить</span>
          </div>
        </div>
      )}
      {userRole === TYPE_ROLE.SUPERVISOR && <UserMenuTask />}
      {userRole === TYPE_ROLE.SUPERVISOR && <UserMenuShift />}
      {userRole === TYPE_ROLE.SUPERVISOR && (
        <div className="user-menu__item user-menu__item--calendar">
          <button
            className={`user-menu__calendar ${newSmena ? 'user-menu__calendar--active' : ''}`}
            onClick={openExtraSmena}
          >
            <CalendarUser />
          </button>
        </div>
      )}
      <button
        data-toggle_aside_popup="user-notifications"
        className={`user-menu__notification-button ${
          unreaded && 'user-menu__notification-button_unreaded'
        }`}
        onClick={openPopupNotifications}
      >
        <BellSvg />
      </button>
      <button
        onClick={handleClickProfile}
        data-toggle_aside_popup="user-profile"
        className="user-menu__user-button user-avatar"
      >
        {initials}
      </button>
    </div>
  );
};

export const getScreenTop = (element: HTMLDivElement | null) => {
  // while (element) {
  //   y += element.offsetTop + element.clientTop;
  //   element = element.offsetParent as HTMLDivElement;
  // }
  if (!element) {
    return 0;
  }

  const bodyRect = document.body.getBoundingClientRect();
  const elemRect = element.getBoundingClientRect();

  return elemRect.top - bodyRect.top;
};

export const getScreenBottom = (element: HTMLDivElement | null) => {
  const y = getScreenTop(element);
  const height = element?.offsetHeight ?? 0;

  return y + height;
};

import PriorityIcon from '~/components/icon/PriorityIcon';
import getPriorityText from '~/helpers/getPriorityText';
import React from 'react';

interface IPriority {
  id: number;
}
const Priority = ({ id }: IPriority) => {
  if (id === null || id === undefined) return null;

  return (
    <div className={`contentPriority contentPriority${id}`}>
      <PriorityIcon id={id} />
      {getPriorityText(id)}
    </div>
  );
};

export default Priority;

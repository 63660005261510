import { Dropdown } from 'antd';
import SelectsField, { ISelect } from '~/components/form/select/Select';
import { ReactComponent as Chevron } from '~/assets/chevron.svg';
import { useState } from 'react';
import { InputActionMeta } from 'react-select';

interface IDropDown {
  select: ISelect;
  className: string;
}

const ComboboxField = ({ className, select }: IDropDown) => {
  const [open, setOpen] = useState(false);

  const onInputChange = (inputValue: string, { action, prevInputValue }: InputActionMeta) => {
    if (action === 'input-change') return inputValue;
    return prevInputValue;
  };

  const performSelectData = {
    ...select,
    className: '',
    placeholder: '',
    tabSelectsValue: false,
    isSearchable: true,
    hiddenValue: true,
    isOnlySearch: true,
    onInputChange: onInputChange,
  };

  const selectAllText = select.selectAllText ?? 'Выбрать все';
  const selectAllValue = select.selectAllValue ?? '-1';

  const isSelectedAll = Object.keys(select.options).length === select.defaultValue.length;

  if (select.addSelectAll ?? Object.keys(select.options).length > 1) {
    performSelectData.preparedOptions = Object.entries(select.options).map(([key, value]) => {
      return { value: key, label: value };
    });
    performSelectData.preparedOptions.unshift({ value: selectAllValue, label: selectAllText });

    // @ts-ignore
    performSelectData.onChange = (newValue: ISelectMultiValue, name: string) => {
      const isClickedSelectAll = newValue.map(item => item.value).includes(selectAllValue);

      if (isClickedSelectAll && !isSelectedAll) {
        newValue = Object.entries(select.options).map(([key, value]) => {
          return { value: key, label: value };
        });
      }

      if (!isClickedSelectAll && isSelectedAll) {
        newValue = [];
      }

      newValue = newValue.filter((item: ISelectOption) => item.value !== selectAllValue);

      // @ts-ignore
      return select.onChange(newValue, name);
    };

    if (isSelectedAll) {
      performSelectData.defaultValue = [
        { value: selectAllValue, label: selectAllText },
        ...performSelectData.defaultValue,
      ];
    }
  }

  const menu = (
    <div className={'combobox__menu'}>
      <SelectsField {...performSelectData} />
    </div>
  );

  const value =
    select.multi && select.defaultValue
      ? select.defaultValue?.map((item: { label: string }) => item.label).join(', ')
      : select.defaultValue;
  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const isSelectedAllValue = isSelectedAll && select.addSelectAll ? selectAllText : value;

  return (
    <Dropdown
      className={'combobox'}
      trigger={['click']}
      overlay={menu}
      visible={open}
      onVisibleChange={handleOpenChange}
      getPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
    >
      <div className={`new-select ${className}`} onClick={e => e.preventDefault()}>
        <div className="new-select__select">
          <div className="new-select__control">
            <div className="new-select__valueContainer">
              <div className="new-select__multiValue">
                {value ? isSelectedAllValue : select.placeholder}
              </div>
            </div>
            <div className="combobox__indicatorContainer">
              <div className="shevron_icon new-select__indicatorContainer" aria-hidden="true">
                <Chevron />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dropdown>
  );
};

export default ComboboxField;

import * as React from 'react';
import { ReactComponent as CaretRightSvg } from '~/assets/caret-right.svg';
import classNames from 'classnames';
import { useAppSelector } from '~/hooks/redux';
import { selectUser } from '~/redux/selectors/usersSelectors';
import { IMapItem } from '~/redux/modules/mapModule';

interface IFC {
  userId: number;
  item?: IMapItem;
  onClick?: () => void;
  className?: string;
}

const MapUser = ({ userId, item, onClick, className }: IFC) => {
  const user = useAppSelector(selectUser(userId));

  if (!user) return null;

  return (
    <div className={classNames('mapUser', className)} onClick={onClick}>
      <div
        className={classNames('mapUser__avatar', {
          ['offline']: !user.online,
        })}
      >
        {user.lastname?.charAt(0)?.toUpperCase()}
        {user.firstname?.charAt(0)?.toUpperCase()}
      </div>
      <div className="mapUser__content">
        <div className="mapUser__item">
          <div className="mapUser__name">
            {user.lastname} {user.firstname?.charAt(0)?.toUpperCase()}.
            {user.middlename?.charAt(0)?.toUpperCase()}.
          </div>
        </div>
      </div>
      {!!item && (
        <div className="mapUser__btn">
          <CaretRightSvg />
        </div>
      )}
    </div>
  );
};

export default MapUser;

interface ILoader {
  width?: number;
  height?: number;
  borderWidth?: number;
}

const Loader = ({ width, height, borderWidth }: ILoader) => {
  return (
    <span
      className="loader"
      style={{ width: width, height: height, borderWidth: borderWidth }}
    ></span>
  );
};

export default Loader;

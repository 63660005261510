import * as React from 'react';

interface IPopupWrapper {
  children: React.ReactChild | React.ReactChild[];
}

const DialogPopupWrapper = ({ children }: IPopupWrapper) => {
  const ref = React.useRef(null);

  return (
    <div className="popupWrapper">
      <div ref={ref}>{children}</div>
    </div>
  );
};

export default DialogPopupWrapper;

import * as React from 'react';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import moment, { Moment } from 'moment';
import { FORMAT_MOMENT } from '~/helpers/constants';

interface IScheduleTemplateInfo {
  dateStart?: string;
  dateEnd?: string;
  setDateStart?: (value: string, name: string) => void;
  setDateEnd?: (value: string, name: string) => void;
  changeVisible?: (value: boolean) => void;
  nameStart?: string;
  nameEnd?: string;
  errorStart?: string;
  errorEnd?: string;
}

export const ScheduleTemplateInfo = ({
  dateStart,
  dateEnd,
  changeVisible,
  setDateStart,
  setDateEnd,
  nameStart,
  nameEnd,
  errorStart,
}: IScheduleTemplateInfo) => {
  const handleCalendarChangeStart = (value: Moment | null) => {
    if (setDateStart) {
      setDateStart(value ? moment(value).format(FORMAT_MOMENT.DASH_YYYYMMDD) : '', nameStart || '');
    }
  };
  const handleCalendarChangeEnd = (value: Moment | null) => {
    if (setDateEnd) {
      setDateEnd(value ? moment(value).format(FORMAT_MOMENT.DASH_YYYYMMDD) : '', nameEnd || '');
    }
  };

  const handleOpenChange = (value: boolean) => {
    if (changeVisible) {
      changeVisible(value);
    }
  };

  return (
    <>
      <div className="ant-wrapper-block">
        <div className="ant-wrapper-block__item">
          <span className="input-block__title">Дата начала</span>
          <DatePicker
            className={errorStart ? 'error' : ''}
            name={nameStart}
            locale={locale}
            placeholder={'Дата начала'}
            onChange={handleCalendarChangeStart}
            disabledDate={date =>
              dateEnd && moment().isBefore(dateEnd) ? date.isAfter(dateEnd) : false
            }
            allowClear={true}
            format={FORMAT_MOMENT.DDMMYYYY}
            value={dateStart ? moment(dateStart) : null}
            onOpenChange={handleOpenChange}
            getPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
            dropdownAlign={{ useCssBottom: true }}
          />
          {!!errorStart && <div className="ant-wrapper-block__error">{errorStart}</div>}
        </div>
        <div className="ant-wrapper-block__item">
          <span className="input-block__title">Дата окончания</span>
          <DatePicker
            name={nameEnd}
            locale={locale}
            placeholder={'Дата окончания'}
            onChange={handleCalendarChangeEnd}
            disabledDate={date =>
              dateStart && moment().isBefore(dateStart)
                ? date.isBefore(dateStart)
                : date.isBefore(moment())
            }
            allowClear={true}
            format={FORMAT_MOMENT.DDMMYYYY}
            value={dateEnd ? moment(dateEnd) : null}
            onOpenChange={handleOpenChange}
            getPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
            dropdownAlign={{ useCssBottom: true }}
          />
        </div>
      </div>
    </>
  );
};

import Button from '~/components/form/buttons/Button';
import { BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';
import AddNewTaskPopupScreen from './AddNewTaskPopupScreen';
import EditTaskPopupScreen from './EditTaskPopupScreen';
import { EWorkRequestType } from '~/types/app';

interface IAddNewTask {
  handleClickBack?: () => void;
  workRequestId: number;
  task?: any;
  workRequestType: EWorkRequestType;
}

const AddOrEditTask = ({ handleClickBack, workRequestId, task, workRequestType }: IAddNewTask) => {
  return (
    <>
      <div className="users-add__header">
        <Button
          type={BUTTON_TYPE.ICON}
          text="назад"
          className="back"
          onClick={handleClickBack}
          icon
          iconType={ICON_TYPE.ARROW_PREV}
        />
      </div>
      {task ? (
        <EditTaskPopupScreen task={task} />
      ) : (
        <AddNewTaskPopupScreen
          template={undefined}
          workRequestId={workRequestId}
          workRequestType={workRequestType}
        />
      )}
    </>
  );
};

export default AddOrEditTask;

import { ReactNode } from 'react';

interface IAlertBlock {
  children: ReactNode;
}
const AlertBlock = ({ children }: IAlertBlock) => {
  return (
    <div className="alert-block">
      <div className="alert-block__icon">
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2.75C10.0716 2.75 8.18657 3.32183 6.58319 4.39317C4.97982 5.46451 3.73013 6.98726 2.99218 8.76884C2.25422 10.5504 2.06114 12.5108 2.43735 14.4021C2.81355 16.2934 3.74215 18.0307 5.10571 19.3943C6.46928 20.7579 8.20656 21.6865 10.0979 22.0627C11.9892 22.4389 13.9496 22.2458 15.7312 21.5078C17.5127 20.7699 19.0355 19.5202 20.1068 17.9168C21.1782 16.3134 21.75 14.4284 21.75 12.5C21.7475 9.9149 20.7195 7.43639 18.8916 5.60845C17.0636 3.78051 14.5851 2.75248 12 2.75ZM12 20.75C10.3683 20.75 8.77326 20.2661 7.41655 19.3596C6.05984 18.4531 5.00242 17.1646 4.378 15.6571C3.75358 14.1496 3.5902 12.4908 3.90853 10.8905C4.22685 9.29016 5.01259 7.82015 6.16637 6.66637C7.32016 5.51259 8.79017 4.72685 10.3905 4.40852C11.9909 4.09019 13.6497 4.25357 15.1571 4.87799C16.6646 5.50242 17.9531 6.55984 18.8596 7.91655C19.7661 9.27325 20.25 10.8683 20.25 12.5C20.2475 14.6873 19.3775 16.7843 17.8309 18.3309C16.2843 19.8775 14.1873 20.7475 12 20.75ZM11.25 13.25V8C11.25 7.80109 11.329 7.61032 11.4697 7.46967C11.6103 7.32902 11.8011 7.25 12 7.25C12.1989 7.25 12.3897 7.32902 12.5303 7.46967C12.671 7.61032 12.75 7.80109 12.75 8V13.25C12.75 13.4489 12.671 13.6397 12.5303 13.7803C12.3897 13.921 12.1989 14 12 14C11.8011 14 11.6103 13.921 11.4697 13.7803C11.329 13.6397 11.25 13.4489 11.25 13.25ZM13.125 16.625C13.125 16.8475 13.059 17.065 12.9354 17.25C12.8118 17.435 12.6361 17.5792 12.4305 17.6644C12.225 17.7495 11.9988 17.7718 11.7805 17.7284C11.5623 17.685 11.3618 17.5778 11.2045 17.4205C11.0472 17.2632 10.94 17.0627 10.8966 16.8445C10.8532 16.6262 10.8755 16.4 10.9606 16.1945C11.0458 15.9889 11.19 15.8132 11.375 15.6896C11.56 15.566 11.7775 15.5 12 15.5C12.2984 15.5 12.5845 15.6185 12.7955 15.8295C13.0065 16.0405 13.125 16.3266 13.125 16.625Z"
            fill="#2F80ED"
          />
        </svg>
      </div>
      <div className="alert-block__text">{children}</div>
    </div>
  );
};

export default AlertBlock;

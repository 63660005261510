import * as React from 'react';
import SearchInput from '~/components/SearchInput';
import { ReactComponent as LocationSvg } from '~/assets/location.svg';
import { Comment } from '~/assets/comment';
import MapUser from '~/components/map/MapUser';
import { useDispatch } from 'react-redux';
import { getShiftGeo } from '~/redux/modules/mapModule';
import { IMapLocation, IMapTaskLog } from 'sb_manufacturing_front_api';
import { useAppSelector } from '~/hooks/redux';
import { selectMapNotCheckinUsersIds, selectMapShift } from '~/redux/selectors/mapSelectors';
import { selectUsers } from '~/redux/selectors/usersSelectors';
import { ReactComponent as BackSvg } from '~/assets/back.svg';
import moment from 'moment';
import { FORMAT_MOMENT } from '~/helpers/constants';
import { ReactComponent as StartTask } from '~/assets/map/startTask/normal.svg';
import { ReactComponent as Manual } from '~/assets/map/manual/normal.svg';
import { ReactComponent as FinishTask } from '~/assets/map/finishTask/normal.svg';
import { ReactComponent as FinishTaskRed } from '~/assets/map/finishTaskDeviation/normal.svg';

export enum ETaskLogText {
  'Назначена задача' = 0,
  'Взял задачу в работу' = 1,
  'Завершил работу' = 2,
  'Отклонил задачу' = 3,
  'Поставил задачу на паузу' = 4,
  'Передал задачу на проверку НУ' = 5,
  'Оставил комментарий' = 6,
  'Отредактировал задачу' = 7,
  'Передал задачу на проверку НС' = 8,
  'НС отклонил задачу' = 9,
  'НУ отклонил задачу' = 10,
  'Отметился в зоне' = 11,
  'Вошел в зону' = 12,
  'Создал задачу' = 13,
  'Оценил задачу' = 14,
  'Просрочил задачу' = 15,
}

export const RenderIcon = (type: number) => {
  switch (type) {
    case 1:
      return <StartTask />;
    case 2:
      return <FinishTask />;
    case 3:
      return <FinishTaskRed />;
    default:
    case 8:
      return <Manual />;
    case 11:
      return <FinishTaskRed />;
    case 14:
      return <Manual />;
    case 15:
      return <FinishTaskRed />;
  }
};

interface IFC {
  infoOpen: number | null;
  setInfoOpen: (value: number | null) => void;
  asideList: IMapLocation[];
  setCurrentTime: (value: string | null) => void;
}

const MapAside = ({ infoOpen, setInfoOpen, asideList, setCurrentTime }: IFC) => {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState<string>('');
  const users = useAppSelector(selectUsers);
  const notCheckinUsersIds = useAppSelector(selectMapNotCheckinUsersIds);
  const currentUserId = asideList?.find(i => i.shift_id === infoOpen)?.user_id || -1;
  const mapShift = useAppSelector(selectMapShift(infoOpen || -1));
  const logs: IMapTaskLog[] | undefined = mapShift?.logs;

  React.useEffect(() => {
    if (infoOpen) dispatch(getShiftGeo(infoOpen));
  }, [infoOpen]);

  return (
    <div className="mapAside">
      <div className="mapAside__controls">
        <SearchInput onChange={text => setSearch(text)} title="Введите имя" />
      </div>
      <div className="mapAside__asideScroll">
        <div className="mapAside__block">
          {!search.length && (
            <div className="mapAside__blockHeader">
              <div className="mapAside__blockTitle">На смене</div>
              <div className="mapAside__blockCount">{asideList?.length} чел.</div>
            </div>
          )}
          <div className="mapAside__blockContent">
            {asideList
              ?.filter(item => {
                if (!search.length) return item;

                const user = users.find(u => u.id === item.user_id);

                if (!user) return;

                if (
                  user.firstname.toLowerCase().includes(search.toLowerCase()) ||
                  user.middlename.toLowerCase().includes(search.toLowerCase()) ||
                  user.lastname.toLowerCase().includes(search.toLowerCase())
                )
                  return item;
              })
              .map((item, key) => (
                <MapUser
                  item={item}
                  userId={item.user_id}
                  key={key}
                  onClick={() => setInfoOpen(item.shift_id)}
                />
              ))}
          </div>
        </div>
        {!search.length && (
          <div className="mapAside__block">
            <div className="mapAside__blockHeader">
              <div className="mapAside__blockTitle">Не на смене</div>
              <div className="mapAside__blockCount">{notCheckinUsersIds?.length || 0} чел.</div>
            </div>
            <div className="mapAside__blockContent">
              {notCheckinUsersIds.map((id, key) => (
                <MapUser userId={id} key={key} />
              ))}
            </div>
          </div>
        )}
      </div>
      {infoOpen && logs && (
        <div className="mapAside__info">
          <div className="mapAside__infoHeader" onClick={() => setInfoOpen(null)}>
            <BackSvg className="new-button__icon" />
            <MapUser userId={currentUserId} className={'withoutHover'} />
          </div>
          <div className="mapAside__infoContent">
            {mapShift?.shift?.check_in_time && (
              <div className="mapAside__blockLogs">
                <div className="mapAside__blockLogsItem">
                  <div
                    className="mapAside__blockLogsTime"
                    onClick={() => setCurrentTime(mapShift?.shift?.check_in_time || null)}
                  >
                    {moment(mapShift?.shift?.check_in_time, FORMAT_MOMENT.YYYYMMDD_HHMMSS).format(
                      FORMAT_MOMENT.HHMM,
                    )}
                  </div>
                  <div
                    className="mapAside__blockLogsIcon"
                    style={{
                      backgroundImage: `url(${
                        require('../../assets/map/checkIn/normal.svg').default
                      })`,
                    }}
                  ></div>
                  <div className="mapAside__blockLogsContent">
                    <div className="mapAside__blockLogsText">
                      <span>Начал смену</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {logs.map((log, key) => (
              <div className="mapAside__blockLogs" key={key}>
                <div className="mapAside__blockLogsItem">
                  <div
                    className="mapAside__blockLogsTime"
                    onClick={() => setCurrentTime(log.time || null)}
                  >
                    {moment(log.time, FORMAT_MOMENT.YYYYMMDD_HHMMSS).format(FORMAT_MOMENT.HHMM)}
                  </div>
                  <div className="mapAside__blockLogsIcon">{RenderIcon(log.type)}</div>
                  <div className="mapAside__blockLogsContent">
                    <div className="mapAside__blockLogsText">
                      <span>{ETaskLogText[log.type]}</span>
                      {log.isExpired && (
                        <span className="mapAside__blockLogsText_isExpired"> (просрочена)</span>
                      )}
                      {!!log.taskTitle.length && (
                        <>
                          <span> · </span>
                          <span className="mapAside__blockLogsText_taskTitle">{log.taskTitle}</span>
                        </>
                      )}
                    </div>
                    {log.zoneName && (
                      <div className="mapAside__blockLogsZone">
                        <LocationSvg />
                        <span>{log.zoneName}</span>
                        {log.geoDeviation && (
                          <span className="mapAside__blockLogsText_geoDeviation">
                            (откл. по гео)
                          </span>
                        )}
                      </div>
                    )}
                    {log.description && (
                      <div className="mapAside__blockLogsComment">
                        <Comment />
                        <span>«{log.description}»</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {mapShift?.shift?.check_out_time && (
              <div className="mapAside__blockLogs">
                <div className="mapAside__blockLogsItem">
                  <div
                    className="mapAside__blockLogsTime"
                    onClick={() => setCurrentTime(mapShift?.shift?.check_out_time || null)}
                  >
                    {moment(mapShift?.shift?.check_out_time, FORMAT_MOMENT.YYYYMMDD_HHMMSS).format(
                      FORMAT_MOMENT.HHMM,
                    )}
                  </div>
                  <div
                    className="mapAside__blockLogsIcon"
                    style={{
                      backgroundImage: `url(${
                        require('../../assets/map/checkOut/normal.svg').default
                      })`,
                    }}
                  ></div>
                  <div className="mapAside__blockLogsContent">
                    <div className="mapAside__blockLogsText">
                      <span>Закончил смену</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MapAside;

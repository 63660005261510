import { persistReducer, persistStore } from 'redux-persist';
import createCompressor from 'redux-persist-transform-compress';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './store';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist/es/constants';
import { configureStore } from '@reduxjs/toolkit';

const compressor = createCompressor();

const persistConfig = {
  key: 'sbm',
  storage,
  transforms: process.env.NODE_ENV === 'development' ? [] : [compressor],
  whitelist: ['auth'],
};

const confStore = () => {
  const reducer = persistReducer(persistConfig, (state, action) =>
    rootReducer(action.type === 'RESET' ? {} : state, action),
  );

  return configureStore({
    reducer,
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, PAUSE, PURGE, PERSIST, REGISTER],
        },
      });
    },
  });
};

const store = confStore();
const persistor = persistStore(store);

export default {
  ...store,
  persistor,
};

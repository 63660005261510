import { ReactNode, useEffect, useState } from 'react';
import {
  ECustomFieldType,
  ERepairRequestStatus,
  ICustomField,
  ICustomFieldTable,
  INewTask,
  IRepairRequest,
} from 'sb_manufacturing_front_api';
import Button from '~/components/form/buttons/Button';
import AsidePopupBody from '~/components/popup/AsidePopupBody';
import AsidePopupHeader from '~/components/popup/AsidePopupHeader';

import { ReactComponent as WarningCircle } from '~/assets/warning-circle-blue.svg';
import Popup from '~/components/popup/Popup';
import Status from '~/components/status/Status';
import { TaskStatus } from '~/components/task-status';
import { BUTTON_TYPE, COLORS, ICON_TYPE } from '~/helpers/constants';
import AddNewTask from '../work-request-popup/AddNewTask';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '~/redux/modules/dialogPopupModule';
import { useAppSelector } from '~/hooks/redux';
import { selectWorkRequestCustomFields } from '~/redux/selectors/workRequestCustomFieldsSelector';
import { getLocalTimeFormat } from '~/helpers/convertToUnix';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { getWordByCustomFieldValue } from '~/utils/getCustomFieldValue';
import { EWorkRequestType } from '~/types/app';
import WorkRequestTable from '~/containers/tasks/popups/work-request-popup/WorkRequestTable';
import { selectUsers } from '~/redux/selectors/usersSelectors';

interface IEditWorkRequestPopup {
  repairRequest: IRepairRequest;
}

interface IPreparedCustomField {
  key: string;
  value: string | ReactNode;
}

const ViewRepairRequestPopup = ({ repairRequest }: IEditWorkRequestPopup) => {
  const dispatch = useDispatch();
  const repairRequestCustomFieldsData = useSelector(selectWorkRequestCustomFields);
  const companyOptions = useAppSelector(selectCompanyOptions);
  const users = useAppSelector(selectUsers);
  const {
    id,
    number,
    status,
    title,
    description,
    start_date,
    end_date,
    custom_fields,
    // images,
    tasks,
    is_published,
  } = repairRequest;
  const [taskOpen, setTaskOpen] = useState<boolean>(false);
  const [task, setTask] = useState<INewTask | undefined>(undefined);
  const [customFields, setCustomFields] = useState<IPreparedCustomField[]>([]);

  useEffect(() => {
    const customFieldsArray: IPreparedCustomField[] = [];

    for (const key in custom_fields) {
      const keyData = repairRequestCustomFieldsData.find(
        item => (item as ICustomField).key === key,
      );
      let fieldTitle = keyData?.name;
      if (keyData) {
        const value = () => {
          const newValue = custom_fields[key];
          switch (keyData.type) {
            case ECustomFieldType.TYPE_SELECT:
              return getWordByCustomFieldValue(
                Number(newValue),
                keyData.settings ? keyData.settings : '',
              );
            case ECustomFieldType.TYPE_TEXT:
              return newValue;
            case ECustomFieldType.TYPE_TABLE:
              fieldTitle = (newValue as ICustomFieldTable).metadata.title ?? keyData?.name;
              return (
                <WorkRequestTable
                  showHeader={(newValue as ICustomFieldTable).metadata.hasHeaders}
                  dataSource={(newValue as ICustomFieldTable).data}
                  columns={(newValue as ICustomFieldTable).metadata.columns}
                />
              );
          }
        };
        customFieldsArray.push({
          value: value(),
          key: fieldTitle ?? '',
        });
      }
    }
    setCustomFields(customFieldsArray);
  }, [custom_fields, repairRequestCustomFieldsData]);

  const handleClickTaskButton = () => {
    setTaskOpen(true);
  };

  const handleClickBack = () => {
    dispatch(
      openDialog({
        data: {
          closable: true,
          dialogData: {
            title: 'Задача не сохранена',
            message: 'Вы уверены, что хотите вернуться назад без сохранения задачи?',
            confirm: {
              button: {
                text: 'Назад к заявке',
                transparent: true,
                background: COLORS.BLUE,
              },
              onConfirm: () => {
                setTaskOpen(false);
                setTask(undefined);
              },
            },
          },
        },
      }),
    );
  };

  const handleClickTask = (task: INewTask) => {
    setTask(task);
    setTaskOpen(true);
  };

  return (
    <Popup aside className="taskPopup work-request-popup">
      <>
        {taskOpen && (
          <div className="users-add">
            <AddNewTask
              handleClickBack={handleClickBack}
              workRequestId={id}
              task={task}
              workRequestType={EWorkRequestType.E_TOIR}
            />
          </div>
        )}
        <AsidePopupHeader>
          <div className="work-request-popup__headerContainer">
            <Status title={status} />
            <h2 className="aside-popup__title aside-popup__title_notifications">{title}</h2>
            {!is_published && (
              <div className="infoBlock">
                <WarningCircle /> Заявка снята с публикации
              </div>
            )}
          </div>
        </AsidePopupHeader>
        <AsidePopupBody>
          <div className="work-request-popup__bodyContainer">
            <div className="work-request-popup__tasksContainer">
              <span className="work-request-popup__tasksContainer__title">Задачи</span>
              {tasks.length > 0 && (
                <div className="work-request-popup__tasksContainer__tasks">
                  {tasks.map(task => {
                    const user = users.find(user => user.id == task.user_id);
                    const initials =
                      (user?.lastname || '').charAt(0) + (user?.firstname || '').charAt(0);
                    return (
                      <div className="work-request-popup__tasksContainer__task">
                        <div className="work-request-popup__tasksContainer__task__avatar">
                          {initials}
                        </div>
                        <div className="work-request-popup__tasksContainer__task__taskBody">
                          <div className="work-request-popup__tasksContainer__task__extraData">
                            <TaskStatus status={task?.status} />
                            <span className="work-request-popup__tasksContainer__task__date">
                              {`${getLocalTimeFormat(
                                task.begin_time,
                                companyOptions.time_zone,
                                'DD.MM.YYYY',
                              )} ${
                                companyOptions.use_schedule_template
                                  ? `, ${task.working_shift_name}`
                                  : ''
                              }`}
                            </span>
                          </div>
                          <div className="work-request-popup__tasksContainer__task__title">
                            <span>{task.title}</span>
                          </div>
                        </div>
                        <Button
                          transparent
                          type={BUTTON_TYPE.ICON}
                          icon
                          iconType={ICON_TYPE.ARROW_NEXT}
                          onClick={() => handleClickTask(task)}
                        />
                      </div>
                    );
                  })}
                </div>
              )}

              {is_published && (
                <Button
                  className="work-request-popup__tasksContainer__button"
                  text="Создать задачу"
                  type={BUTTON_TYPE.ICON}
                  iconType={ICON_TYPE.CREATE}
                  icon
                  transparent
                  disabled={[ERepairRequestStatus.E_CLOSED].includes(status)}
                  onClick={handleClickTaskButton}
                />
              )}
            </div>
            <div className="work-request-popup__propertyItemsGroup">
              <div className="work-request-popup__propertyItem">
                <span className="work-request-popup__propertyItem__title">Номер заявки</span>
                <span className="work-request-popup__propertyItem__value">{number}</span>
              </div>
              <div className="work-request-popup__propertyItem">
                <span className="work-request-popup__propertyItem__title">
                  Начало выполнения работ
                </span>
                <span className="work-request-popup__propertyItem__value">{start_date}</span>
              </div>
              <div className="work-request-popup__propertyItem">
                <span className="work-request-popup__propertyItem__title">
                  Окончание выполнения работ
                </span>
                <span className="work-request-popup__propertyItem__value">{end_date}</span>
              </div>
            </div>

            <div className="work-request-popup__propertyItem">
              <span className="work-request-popup__propertyItem__title">Описание</span>
              <span className="work-request-popup__propertyItem__value">{description}</span>
              {/*FIXME: По говотовности с бека добавить картинки*/}
              {/*<FileInput className="viewTaskPopup__files" value={images} readonly />*/}
            </div>

            {customFields.map((customField, index) => (
              <div key={index} className="work-request-popup__propertyItem">
                <span className="work-request-popup__propertyItem__title">{customField.key}</span>
                <span className="work-request-popup__propertyItem__value">{customField.value}</span>
              </div>
            ))}
          </div>
        </AsidePopupBody>
      </>
    </Popup>
  );
};

export default ViewRepairRequestPopup;

import * as React from 'react';
import { INewTaskTemplate, ITaskTimelessTemplate } from 'sb_manufacturing_front_api';
import Template from '~/containers/tasks/template';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { useAppSelector } from '~/hooks/redux';
import { selectFilteredDepartmentIds } from '~/redux/selectors/stateSelectors';
import { selectWorkShiftIds } from '~/redux/selectors/stateSelectors';
import { selectTemplates } from '~/redux/selectors/tasksTemplatesSelectors';
import { selectTimelessTemplates } from '~/redux/selectors/tasksTimelessTemplatesSelectors';

type TTemplate = (INewTaskTemplate | ITaskTimelessTemplate)[];

export const Templates = () => {
  SwiperCore.use([Navigation]);

  const departmentIds = useAppSelector(selectFilteredDepartmentIds);
  const workShiftIds = useAppSelector(selectWorkShiftIds);
  const tasksTemplates = useAppSelector(selectTemplates);
  const timelessTemplates = useAppSelector(selectTimelessTemplates);

  const templatesAll: TTemplate = [...timelessTemplates, ...tasksTemplates];

  const preparedTemplates = templatesAll.filter(template => {
    if ('working_shift_id' in template) {
      return (
        workShiftIds?.includes(template.working_shift_id) &&
        departmentIds.includes(template.department_id)
      );
    }
    return departmentIds.includes(template.department_id);
  });

  if (!preparedTemplates.length) {
    return (
      <div className="templates__none">
        <p>У вас пока нет шаблонов задач</p>
        <span>Нажмите «Создать задачу», чтобы создать задачу или сохранить шаблон</span>
      </div>
    );
  } else {
    return (
      <Swiper
        className="templates__templates"
        spaceBetween={8}
        slidesPerView={'auto'}
        navigation
        grabCursor
      >
        {templatesAll.map(item => {
          if ('working_shift_id' in item) {
            if (
              departmentIds.includes(item.department_id) &&
              workShiftIds?.includes(item.working_shift_id)
            ) {
              return (
                <SwiperSlide key={item.id}>
                  <Template item={item} />
                </SwiperSlide>
              );
            }
          } else {
            if (departmentIds.includes(item.department_id)) {
              return (
                <SwiperSlide key={item.id}>
                  <Template item={item} />
                </SwiperSlide>
              );
            }
          }
        })}
      </Swiper>
    );
  }
};

import { useState } from 'react';
import { TaskNoTime } from '~/containers/tasks/task/TaskNoTime';
import { TaskNoTimeInWork } from '~/containers/tasks/task/TaskNoTimeInWork';
import { TasksNoTimeFilter } from '~/containers/tasks/filter/TasksNoTimeFilter';
import { useSelector } from 'react-redux';
import {
  selectTasksTimelessDefault,
  selectTasksTimelessInProgress,
  selectTasksTimelessComplete,
  selectTasksTimelessMe,
} from '~/redux/selectors/tasksTimelessSelectors';
import { selectCurrentUserId, selectUserRole } from '~/redux/selectors/authSelectors';
import { TYPE_ROLE } from '~/helpers/constants';
import { selectFilteredDepartmentIds } from '~/redux/selectors/stateSelectors';

export const TasksNoTime = () => {
  const [filterInProgress, setFilterInProgress] = useState<[string, boolean]>(['', false]);
  const [filterComplete, setFilterComplete] = useState<[string, boolean]>(['', false]);
  const [filterMe, setFilterMe] = useState<[string, boolean]>(['', false]);

  const userRole = useSelector(selectUserRole);
  const departmentIds = useSelector(selectFilteredDepartmentIds);
  const userId = useSelector(selectCurrentUserId);
  const taskTimelessDefault = useSelector(selectTasksTimelessDefault(departmentIds));
  const taskTimelessMe = useSelector(selectTasksTimelessMe(filterMe, userId, departmentIds));
  const taskTimelessInProgress = useSelector(
    selectTasksTimelessInProgress(filterInProgress, departmentIds),
  );
  const taskTimelessComplete = useSelector(
    selectTasksTimelessComplete(filterComplete, departmentIds),
  );

  return (
    <div className="tasks-no-time">
      <div className="tasks-no-time__wrapper">
        <div className="tasks-no-time__free">
          <div className="tasks-no-time__title">Свободные задачи</div>
          <div className="tasks-no-time__free-container">
            {taskTimelessDefault.length ? (
              <>
                {taskTimelessDefault.map((item, key) => (
                  <TaskNoTime task={item} key={key} />
                ))}
              </>
            ) : (
              <div className="tasks-no-time__no-task">Сейчас нет назначенных задач</div>
            )}
          </div>
        </div>
        <div className="tasks-no-time__line" />
        <div className="tasks-no-time__in-work">
          {TYPE_ROLE.SUPERVISOR === userRole ? (
            <>
              <div className="tasks-no-time__title">Мои задачи</div>
              {taskTimelessMe.length ? (
                <>
                  <TasksNoTimeFilter setFilter={value => setFilterMe(value)} filter={filterMe} />
                  <div className="tasks-no-time__in-work-container">
                    {taskTimelessMe.map((item, key) => (
                      <TaskNoTimeInWork isMe={true} task={item} key={key} />
                    ))}
                  </div>
                </>
              ) : (
                <div className="tasks-no-time__no-task">Сейчас у вас нет задач</div>
              )}
            </>
          ) : null}
          <div className="tasks-no-time__title tasks-no-time__title--two">Текущие</div>
          {taskTimelessInProgress.length ? (
            <>
              <TasksNoTimeFilter
                setFilter={value => setFilterInProgress(value)}
                filter={filterInProgress}
              />
              <div className="tasks-no-time__in-work-container">
                {taskTimelessInProgress.map((item, key) => (
                  <TaskNoTimeInWork task={item} key={key} />
                ))}
              </div>
            </>
          ) : (
            <div className="tasks-no-time__no-task">Сейчас нет задач в работе</div>
          )}
          <div className="tasks-no-time__title tasks-no-time__title--two">Прошедшие</div>
          {taskTimelessComplete.length ? (
            <>
              <TasksNoTimeFilter
                setFilter={value => setFilterComplete(value)}
                filter={filterComplete}
              />
              <div className="tasks-no-time__in-work-container">
                {taskTimelessComplete.map((item, key) => (
                  <TaskNoTimeInWork task={item} key={key} />
                ))}
              </div>
            </>
          ) : (
            <div className="tasks-no-time__no-task">Сейчас нет выполненных задач</div>
          )}
        </div>
      </div>
    </div>
  );
};

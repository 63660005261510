import { useEffect, useState } from 'react';
import Button from '~/components/form/buttons/Button';
import AsidePopupBody from '~/components/popup/AsidePopupBody';
import AsidePopupHeader from '~/components/popup/AsidePopupHeader';
import Popup from '~/components/popup/Popup';
import { COLORS, NAMES_FIELD } from '~/helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import TextareasField from '~/components/form/textarea/Textarea';
import { FileInput } from '~/components';
import * as React from 'react';
import onInput from '~/utils/onInput';
import SelectsField from '~/components/form/select/Select';
import { toast } from 'react-toastify';
import { closePopup } from '~/redux/modules/popupModule';
import AlertBlock from '~/components/alert/AlertBlock';
import Risk, { RiskChar, RiskNumber } from '~/components/status/Risk';
import { selectDepartmentId } from '~/redux/selectors/stateSelectors';
import { selectDepartments } from '~/redux/selectors/departmentsSelectors';
import errorHandler from '~/utils/errorHandler';
import { createWorkRequestPos } from '~/redux/modules/workRequestsModule';
import { Title } from '~/containers/tasks/popups/task-popup/title';
import { Image } from 'antd';
import matrixImage from '~/assets/risk/matrix1.jpg';
import matrix2Image from '~/assets/risk/matrix2.jpg';
import { useAppSelector } from '~/hooks/redux';
import { selectCurrentUserId } from '~/redux/selectors/authSelectors';
import { selectUser } from '~/redux/selectors/usersSelectors';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';

const CreateWorkRequestPopup = () => {
  const dispatch = useDispatch();
  const departmentId = useSelector(selectDepartmentId);
  const [riskChar, setRiskChar] = useState<RiskChar>('A');
  const [riskNumber, setRiskNumber] = useState<RiskNumber>('1');
  const [visible, setVisible] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const departaments = useSelector(selectDepartments);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const user = useAppSelector(selectUser(currentUserId));
  const companyOptions = useAppSelector(selectCompanyOptions);
  const allUserDepartments = [
    ...(user ? user.departments_ids : []),
    ...(user ? user.view_departments_ids : []),
  ];
  const isDepartmentPrombezMatch = user?.departments_ids.includes(
    companyOptions.prom_bez_department_id,
  );
  const userDepartments = isDepartmentPrombezMatch
    ? departaments
    : departaments.filter(item => allUserDepartments.includes(item.id));
  const preparedDepartaments: ISelectOption = {};
  const riskChars = {
    A: 'A — Незначительные последствия',
    B: 'B — Малозначительные последствия',
    C: 'C — Средние последствия',
    D: 'D — Серьезные последствия',
    E: 'E — Критические последствия',
  };
  const riskNumbers = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  };

  userDepartments.forEach(item => (preparedDepartaments[item.id] = item.name));

  const [state, setState] = React.useState<IStateForm>({
    [NAMES_FIELD.PRIORITY]: 0,
    [NAMES_FIELD.SCHEDULE_TIME_HOURS]: 0,
    [NAMES_FIELD.SCHEDULE_TIME_MINUTES]: 0,
    [NAMES_FIELD.CLASSIFICATION_AUTHOR_RISK]: 'A1',
    [NAMES_FIELD.DEPARTMENT_ID]: departmentId,
    [NAMES_FIELD.GEO]: [],
    [NAMES_FIELD.AS_TEMPLATE]: false,
    [NAMES_FIELD.IS_REPEAT]: false,
    [NAMES_FIELD.USERS]: [],
  });

  useEffect(() => {
    setState({ ...state, [NAMES_FIELD.CLASSIFICATION_AUTHOR_RISK]: `${riskChar}${riskNumber}` });
  }, [riskChar, riskNumber]);

  const [, setErrors] = React.useState<{ [name: string]: string }>({});

  const handelOnInput = (value: string | boolean | number, name: string) => {
    onInput({
      value,
      name,
      state,
      setState,
      setErrors,
    });
  };

  const setValues = (value: any) => {
    setTimeout(() => {
      setState(prevState => {
        return {
          ...prevState,
          [NAMES_FIELD.TASK_ID]: value?.id,
          [NAMES_FIELD.PRIORITY]: value?.priority || 0,
          [NAMES_FIELD.UNFIXED]: value?.unfixed || false,
          [NAMES_FIELD.GEO]: value?.geo || [],
          [NAMES_FIELD.IS_REPEAT]: false,
          [NAMES_FIELD.NEED_PHOTO_REPORT_BEFORE_START]: value?.need_photo_report_before_start,
          [NAMES_FIELD.NEED_PHOTO_REPORT_AFTER_COMPLETE]: value?.need_photo_report_after_complete,
          [NAMES_FIELD.NEED_DEPARTMENT_TEST]: value?.need_department_test,
          [NAMES_FIELD.NEED_SUPERVISOR_TEST]: value?.need_supervisor_test,
          [NAMES_FIELD.NEED_PATH_PHOTO_REPORT]: value?.need_path_photo_report,
          [NAMES_FIELD.DESCRIPTION]: value?.description,
          [NAMES_FIELD.ATTACHMENT]: value?.images,
          [NAMES_FIELD.TITLE]: value?.title,
          [NAMES_FIELD.TEMPLATE_ID]: value?.id,
        };
      });
    }, 50);
  };

  const handleCreateWorkRequest = async () => {
    setLoading(true);

    const success = (text: string) => {
      toast.success(text);
      dispatch(closePopup());
    };

    const valueForm = {
      task_template_id: state[NAMES_FIELD.TEMPLATE_ID],
      title: state[NAMES_FIELD.TITLE],
      description: state[NAMES_FIELD.DESCRIPTION],
      images: state[NAMES_FIELD.ATTACHMENT],
      need_path_photo_report: state[NAMES_FIELD.NEED_PATH_PHOTO_REPORT],
      need_supervisor_test: state[NAMES_FIELD.NEED_SUPERVISOR_TEST],
      need_department_test: state[NAMES_FIELD.NEED_DEPARTMENT_TEST],
      classification_author_risk: state[NAMES_FIELD.CLASSIFICATION_AUTHOR_RISK],
      need_photo_report_before_start: state[NAMES_FIELD.NEED_PHOTO_REPORT_BEFORE_START],
      need_photo_report_after_complete: state[NAMES_FIELD.NEED_PHOTO_REPORT_AFTER_COMPLETE],
      need_comment: state[NAMES_FIELD.IS_COMMENT],
      duration:
        (Number(state[NAMES_FIELD.SCHEDULE_TIME_MINUTES]) +
          Number(state[NAMES_FIELD.SCHEDULE_TIME_HOURS]) * 60) *
        60,
      users_ids: state[NAMES_FIELD.USERS],
      priority: state[NAMES_FIELD.PRIORITY],
      type_id: Number(state[NAMES_FIELD.TYPE_ID]),
      geo_type: Number(state[NAMES_FIELD.GEO_TYPE]),
      geo: state[NAMES_FIELD.GEO],
      department_id: state[NAMES_FIELD.DEPARTMENT_ID],
    };

    const result: any = await dispatch(createWorkRequestPos(valueForm));
    if (result.payload.kind === 'ok') {
      success('Задача без времени создана');
    } else errorHandler(result.payload);
    setLoading(false);
  };

  const handleRisksMatrixButtonClick = () => {
    setVisible(true);
  };
  return (
    <Popup aside className="taskPopup work-request-popup">
      <>
        <AsidePopupHeader>
          <div className="work-request-popup__headerContainer">
            <h2 className="aside-popup__title aside-popup__title_notifications">
              Создать заявку для ПОС
            </h2>
          </div>
        </AsidePopupHeader>
        <AsidePopupBody>
          <div className="work-request-popup__bodyContainer">
            <div className="work-request__block">
              <Title
                onSelectItem={setValues}
                value={state[NAMES_FIELD.TITLE]}
                maxLength={255}
                onInput={event => handelOnInput(event.currentTarget.value, NAMES_FIELD.TITLE)}
              />
            </div>
            <div className="work-request__block">
              <SelectsField
                name={NAMES_FIELD.DEPARTMENT_ID}
                label="Цех"
                options={preparedDepartaments}
                onChange={(value, name) => handelOnInput(value!.value, name)}
                defaultValue={state[NAMES_FIELD.DEPARTMENT_ID]}
              />
            </div>
            <div className="work-request-popup__block">
              <Risk
                riskCategory={state[NAMES_FIELD.CLASSIFICATION_AUTHOR_RISK]}
                type="AlertBlock"
              />
              <AlertBlock>
                <p>
                  Классификация риска — это совокупность двух параметров: серьезности последствий и
                  вероятности их наступления. Выберите значения ниже, ответственно оценивая каждую
                  ситуацию.
                </p>
                <br />
                <p>
                  Подробности в{' '}
                  <span
                    tabIndex={1}
                    role="button"
                    className="data"
                    onClick={handleRisksMatrixButtonClick}
                  >
                    матрице оценки рисков.
                  </span>
                </p>
                <Image.PreviewGroup
                  preview={{
                    visible,
                    onVisibleChange: value => {
                      setVisible(value);
                    },
                  }}
                >
                  <Image style={{ display: 'none' }} src={matrixImage} />
                  <Image style={{ display: 'none' }} src={matrix2Image} />
                </Image.PreviewGroup>
              </AlertBlock>
              <div className="work-request-popup__propertyItemsGroupRow">
                <SelectsField
                  name={NAMES_FIELD.DEPARTMENT_ID}
                  label="Серьезность последствий"
                  options={riskChars}
                  onChange={value => setRiskChar(value!.value as RiskChar)}
                  defaultValue={riskChar}
                />
                <SelectsField
                  name={NAMES_FIELD.DEPARTMENT_ID}
                  label="Вероятность события"
                  options={riskNumbers}
                  onChange={value => setRiskNumber(value?.value as RiskNumber)}
                  defaultValue={riskNumber}
                />
              </div>
            </div>
            <div className="work-request-popup__block">
              <div className="textarea-wrapper">
                <TextareasField
                  name={NAMES_FIELD.DESCRIPTION}
                  label="Описание"
                  placeholder="Введите описание задачи"
                  defaultValue={state[NAMES_FIELD.DESCRIPTION]}
                  onInput={(value, name) => handelOnInput(value, name)}
                />
              </div>
              <FileInput
                limit={10}
                value={state[NAMES_FIELD.ATTACHMENT]}
                onChange={e => {
                  setState(prevState => {
                    return {
                      ...prevState,
                      [NAMES_FIELD.ATTACHMENT]: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className="work-request-popup__block">
              <Button
                text="Сохранить"
                background={COLORS.BLUE}
                disabled={loading}
                onClick={handleCreateWorkRequest}
              />
            </div>
          </div>
        </AsidePopupBody>
      </>
    </Popup>
  );
};
export default CreateWorkRequestPopup;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IPopupState {
  name: string;
  isVisible?: boolean;
  isAside?: boolean;
  data?: any;
}

const initialState: IPopupState = {
  name: '',
};

export const popupSlice = createSlice({
  name: 'popupSlice',
  initialState,
  reducers: {
    openPopup(state, action: PayloadAction<IPopupState>) {
      state.name = action.payload.name;
      state.data = action.payload.data;
      state.isAside = action.payload.isAside;
      state.isVisible = true;
    },
    closePopup(state) {
      state.isVisible = false;
    },
  },
});

export default popupSlice.reducer;
export const { openPopup, closePopup } = popupSlice.actions;

import { ReactNode, useEffect, useState } from 'react';
import Button from '~/components/form/buttons/Button';
import { BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';
import * as React from 'react';

interface IShowHide {
  title?: string;
  name?: string;
  defaultSpoiler?: boolean;
  showHide?: boolean;
  buttons?: ReactNode;
  children?: ReactNode;
}

const ScreenBlock = (props: IShowHide) => {
  const { title, showHide, buttons, children, name, defaultSpoiler } = props;
  const [hide, setHide] = useState<boolean>(
    localStorage.getItem(`${name}__spoiler`)
      ? localStorage.getItem(`${name}__spoiler`) === 'true'
      : !!defaultSpoiler,
  );

  useEffect(() => {
    if (name) {
      localStorage.setItem(`${name}__spoiler`, hide.toString());
    }
  }, [hide, name]);

  return (
    <div className="templates">
      <div className="templates__controls templates__controls--inline">
        <h2 className="templates__title">{title}</h2>
        <div className="tabs">
          {buttons}
          {showHide && (
            <Button
              type={BUTTON_TYPE.ICON}
              onClick={() => setHide(!hide)}
              className={'tabs__tab'}
              icon
              reverse
              text={hide ? 'Показать' : 'Скрыть'}
              iconType={hide ? ICON_TYPE.ARROW_BOTTOM : ICON_TYPE.ARROW_TOP}
            />
          )}
        </div>
      </div>
      <div
        style={{
          height: hide ? 0 : '100%',
          overflow: hide ? 'hidden' : 'visible',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ScreenBlock;

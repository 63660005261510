import * as React from 'react';
import { Image } from 'antd';
import Api, { StringResult } from 'sb_manufacturing_front_api';
import * as R from 'ramda';
import { CancelToken } from 'apisauce';
import { HOST } from '~/utils';
import { PdfPreview } from './pdf-viewer';
import { ReactComponent as EyeFileSvg } from '~/assets/eye-file.svg';
import { ReactComponent as CreateSvg } from '~/assets/create.svg';
import { ReactComponent as DeleteSvg } from '~/assets/delete-circle.svg';
import { BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';
import Button from '~/components/form/buttons/Button';
import Loader from '~/components/loader/Loader';

const readFile = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = e => {
        if (e.target && e.target.result) {
          resolve(e.target.result as string);
        }
      };

      reader.onerror = e => {
        reject(e.target?.error);
      };

      reader.readAsDataURL(file);
    }
  });
};

interface SimplifiedEvent<T> {
  target: {
    value: T;
  };
}

interface IFileInput {
  value?: string[];
  onChange?: (e: SimplifiedEvent<string[]>) => void;
  readonly?: boolean;
  className?: string;
  limit?: number;
}

interface IImagePreview {
  value?: string;
  idx: number;
  onRemove?: (idx: number) => void;
  onUpload: (file: string, idx: number) => void;
  readonly?: boolean;
  type?: string;
}

const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];

function normalizeUrl(url: string) {
  return url.includes(HOST) ? url : `${HOST}${url}`;
}

const NoneFiles = ({ onSelect }: { onSelect: (file: string) => void }) => {
  const [file, setFile] = React.useState<File | undefined>();

  React.useEffect(() => {
    if (file) {
      const cancelToken = CancelToken.source();
      (async () => {
        const result: StringResult = await Api.uploadImage(file, {
          cancelToken: cancelToken.token,
        });
        if (result.kind === 'ok') {
          onSelect(result.data);
        }
      })();
      return cancelToken.cancel;
    }
  }, [file]);

  const handleInputChange = React.useCallback(
    event => {
      setFile(event.target.files[0]);
    },
    [onSelect],
  );
  return (
    <>
      <label className="file-input">
        <input
          type="file"
          className="file-input__input"
          onChange={handleInputChange}
          accept={ALLOWED_TYPES.join(',')}
        />
        <Button type={BUTTON_TYPE.ICON} text="Прикрепить файл" icon iconType={ICON_TYPE.FILE} />
      </label>
      <div className="file-input__description">
        Вы можете прикрепить jpg, png или pdf весом до 50 мб
      </div>
    </>
  );
};

const ImagePreview = ({ value, idx, onRemove, onUpload, readonly, type }: IImagePreview) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const previewOptionsRef = React.useRef({
    mask: <EyeFileSvg />,
    getContainer: () => containerRef.current!,
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [uri, setUri] = React.useState('');
  const [file, setFile] = React.useState<File | undefined>();
  const isEmpty = !value && uri === '';
  const handleRemove = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onRemove && onRemove(idx);
    },
    [idx],
  );

  React.useEffect(() => {
    if (value) {
      setUri('');
      setFile(undefined);
    }
  }, [value]);

  React.useEffect(() => {
    if (file) {
      setIsLoading(true);
      const cancelToken = CancelToken.source();
      (async () => {
        const result: StringResult = await Api.uploadImage(file, {
          cancelToken: cancelToken.token,
        });
        if (result.kind === 'ok' && result.data) {
          onUpload(result.data, idx);
        }
        setFile(undefined);
        setUri('');
        setIsLoading(false);
      })();
      return cancelToken.cancel;
    }
  }, [file]);

  const handleInputChange = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = (event.target as HTMLInputElement).files![0];
      const dataURI = await readFile(file);
      setUri(dataURI);
      setFile(file);
    },
    [],
  );

  return (
    <div className="preview" ref={containerRef}>
      <label className={`preview__item ${isEmpty ? 'border' : ''} square`}>
        {isEmpty && (
          <input
            type="file"
            className="file-input__input"
            onChange={handleInputChange}
            accept={ALLOWED_TYPES.join(',')}
            disabled={readonly}
          />
        )}
        {value ? (
          <>
            {type === 'pdf' ? (
              <PdfPreview value={value} />
            ) : (
              <Image src={value} preview={previewOptionsRef.current} />
            )}
          </>
        ) : isEmpty ? (
          <CreateSvg />
        ) : isLoading ? (
          <Loader width={18} height={18} borderWidth={2} />
        ) : (
          <Image src={uri} preview={previewOptionsRef.current} />
        )}
      </label>
      {onRemove && !readonly && (
        <DeleteSvg className="preview__itemDelete" onClick={handleRemove} />
      )}
    </div>
  );
};

export const FileInput = ({ value = [], onChange, readonly, limit }: IFileInput) => {
  const [files, setFiles] = React.useState<string[]>(value);
  const handleRemove = React.useCallback((idx: number) => {
    setFiles(files => {
      const newFiles = [...files];
      if (files[idx]) {
        newFiles.splice(idx, 1);
      }
      return newFiles;
    });
  }, []);

  const handleSingleSelect = React.useCallback((file: string) => {
    setFiles([file]);
  }, []);

  const handleMultiSelect = React.useCallback((file: string, idx: number) => {
    setFiles(value => {
      if (files.length > 6) return value;
      const newFiles = [...value];
      if (value[idx]) {
        newFiles[idx] = file;
      } else {
        newFiles.unshift(file);
      }

      return newFiles;
    });
  }, []);

  React.useEffect(() => {
    setFiles(value);
  }, [JSON.stringify(value)]);

  React.useEffect(() => {
    if (!R.equals(files, value)) {
      onChange && onChange({ target: { value: files } });
    }
  }, [files]);

  if (readonly && files.length === 0) {
    return null;
  }
  const limitCondition = limit ? files.length < limit : true;

  return (
    <div className="files">
      {!files.length && !readonly && <NoneFiles onSelect={handleSingleSelect} />}
      <div className="files__wrapper">
        {!!files.length && (
          <>
            {!readonly && limitCondition && (
              <ImagePreview idx={files.length + 1} value={''} onUpload={handleMultiSelect} />
            )}
            <Image.PreviewGroup>
              {files.map((uri, idx) => (
                <ImagePreview
                  key={idx}
                  idx={idx}
                  type={uri.split('.').reverse()[0]}
                  value={normalizeUrl(uri)}
                  onRemove={handleRemove}
                  onUpload={handleMultiSelect}
                  readonly={readonly}
                />
              ))}
            </Image.PreviewGroup>
          </>
        )}
      </div>
    </div>
  );
};

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { useAppSelector } from '~/hooks/redux';
import WorkRequest from './workRequest';
import {
  selectWorkRequests,
  selectWorkRequestsLoading,
} from '~/redux/selectors/workRequestsSelectors';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getWorkRequests } from '~/redux/modules/workRequestsModule';
import { selectFilteredDepartmentIds } from '~/redux/selectors/stateSelectors';
import WorkRequestsSkeleton from '~/containers/tasks/WorkRequestsSkeleton';
import { selectCurrentUserId } from '~/redux/selectors/authSelectors';
import { selectUser } from '~/redux/selectors/usersSelectors';
import { getRepairRequests } from '~/redux/modules/repairRequestsModule';
import {
  selectRepairRequests,
  selectRepairRequestsLoading,
  selectRepairRequestsTotalCount,
} from '~/redux/selectors/repairRequestsSelectors';
import RepairRequest from '~/containers/tasks/RepairRequest';
import { EWorkRequestType } from '~/types/app';

interface IWorkRequestsProps {
  type?: EWorkRequestType;
}
const swiperSpaceBetween = 8;
export const WorkRequests = ({ type = EWorkRequestType.E_TOIR }: IWorkRequestsProps) => {
  const dispatch = useDispatch();
  SwiperCore.use([Navigation]);
  const departmentIds = useAppSelector(selectFilteredDepartmentIds);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const user = useAppSelector(selectUser(currentUserId));

  const workRequests = useAppSelector(selectWorkRequests);
  const repairRequests = useAppSelector(selectRepairRequests);
  const isLoadingWorkRequests = useAppSelector(selectWorkRequestsLoading);
  const isLoadingRepairRequests = useAppSelector(selectRepairRequestsLoading);
  const totalCount = useAppSelector(selectRepairRequestsTotalCount);

  const offset = EWorkRequestType.E_TOIR === type ? repairRequests.length : workRequests.length;
  const loadMethod = EWorkRequestType.E_TOIR === type ? getRepairRequests : getWorkRequests;
  const isLoading =
    EWorkRequestType.E_TOIR === type ? isLoadingRepairRequests : isLoadingWorkRequests;

  const filteredDepartments = () => {
    return departmentIds.filter(
      item => user?.departments_ids.includes(item) || user?.view_departments_ids.includes(item),
    );
  };

  const getItems = (offset = 0, limit = 10) => {
    if (!isLoading) {
      dispatch(
        loadMethod({
          limit,
          department_ids: filteredDepartments(),
          offset,
        }),
      );
    }
  };

  useEffect(() => {
    getItems(0, 10);
  }, [...departmentIds, type]);

  return (type === EWorkRequestType.E_POS && !workRequests.length) ||
    (type === EWorkRequestType.E_TOIR && !repairRequests.length) ? (
    <div className="applications__none">
      <p>У вас пока нет заявок</p>
    </div>
  ) : (
    <Swiper
      className="applications__applications"
      spaceBetween={swiperSpaceBetween}
      slidesPerView={'auto'}
      navigation
      grabCursor
      onProgress={(_, progress) => {
        if (progress > 0.7 && type === EWorkRequestType.E_TOIR && offset < totalCount) {
          getItems(offset, 10);
        }
      }}
    >
      {type === EWorkRequestType.E_TOIR &&
        repairRequests
          .slice(0)
          .reverse()
          .map(item => {
            return (
              <SwiperSlide key={item.id}>
                <RepairRequest {...item} />
              </SwiperSlide>
            );
          })}

      {type === EWorkRequestType.E_POS &&
        workRequests.map(item => {
          return (
            <SwiperSlide key={item.id}>
              <WorkRequest {...item} />
            </SwiperSlide>
          );
        })}
      {(isLoadingWorkRequests || isLoadingRepairRequests) && (
        <SwiperSlide>
          <WorkRequestsSkeleton />
        </SwiperSlide>
      )}
    </Swiper>
  );
};

import {
  ERepairRequestStatus,
  EWorkRequestStatus,
  EWorkRequestStatusTitle,
} from 'sb_manufacturing_front_api';

interface IStatus {
  title: EWorkRequestStatus | ERepairRequestStatus;
}

const Status = ({ title }: IStatus) => {
  return (
    <div className={`contentStatus contentPriority${title}`}>
      {Object.values(EWorkRequestStatusTitle)[title]}
    </div>
  );
};

export default Status;

import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';

import { repairRequestsAdapter } from '~/redux/modules/repairRequestsModule';

export const repairRequestsSelector = repairRequestsAdapter.getSelectors<IStore>(
  state => state.repairRequests,
);

export const selectRepairRequests = createSelector(
  (state: IStore) => repairRequestsSelector.selectAll(state),
  repairRequests => repairRequests,
);

export const selectRepairRequestsLoading = createSelector(
  (state: IStore) => state.repairRequests.isLoading,
  isLoading => isLoading,
);

export const selectRepairRequestsTotalCount = createSelector(
  (state: IStore) => state.repairRequests.total_count,
  totalCount => totalCount,
);

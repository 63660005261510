export type RiskChar = 'A' | 'B' | 'C' | 'D' | 'E';
export type RiskNumber = '1' | '2' | '3' | '4' | '5';

const riskTypes = ['Низкий риск', 'Умеренный риск', 'Высокий риск', 'Критический риск'];
const riskCharNumber = {
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
};

interface IRiskProps {
  riskCategory: string;
  type?: 'AlertBlock';
}
const Risk = ({ riskCategory, type }: IRiskProps) => {
  const riskChar = riskCategory.slice(0, 1) as RiskChar;
  const riskNumber = riskCategory.slice(1, 2);
  const value = riskCharNumber[riskChar] * Number(riskNumber);

  let riskType = 0;

  switch (true) {
    case value >= 20:
      riskType = 3;
      break;
    case value < 5:
      riskType = 0;
      break;
    case value < 10:
      riskType = 1;
      break;
    case value < 20:
      riskType = 2;
      break;
  }

  return (
    <div className={`risk risk--${riskType} ${type === 'AlertBlock' ? 'risk-block' : ''}`}>
      <div className={`riskImage riskImage--${riskType}`}></div>
      {type === 'AlertBlock' && <span>{riskCategory} - </span>}
      <div>{riskTypes[riskType]}</div>
    </div>
  );
};

export default Risk;

import * as React from 'react';
import { useEffect, useState } from 'react';
import { IWorkRequest } from 'sb_manufacturing_front_api';
import Button from '~/components/form/buttons/Button';
import AsidePopupBody from '~/components/popup/AsidePopupBody';
import AsidePopupHeader from '~/components/popup/AsidePopupHeader';
import Popup from '~/components/popup/Popup';
import { COLORS, NAMES_FIELD } from '~/helpers/constants';
import AddNewTask from './AddNewTask';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '~/redux/modules/dialogPopupModule';
import SelectsField from '~/components/form/select/Select';
import Risk from '~/components/status/Risk';
import AlertBlock from '~/components/alert/AlertBlock';
import onInput from '~/utils/onInput';
import { Title } from '~/containers/tasks/popups/task-popup/title';
import { selectDepartments } from '~/redux/selectors/departmentsSelectors';
import TextareasField from '~/components/form/textarea/Textarea';
import { FileInput } from '~/components';
import { toast } from 'react-toastify';
import { closePopup, openPopup } from '~/redux/modules/popupModule';
import { editWorkRequestPos } from '~/redux/modules/workRequestsModule';
import errorHandler from '~/utils/errorHandler';
import { POPUPS_NAME } from '~/components/PopupManager';
import { Image } from 'antd';
import matrixImage from '~/assets/risk/matrix1.jpg';
import matrix2Image from '~/assets/risk/matrix2.jpg';
import { useAppSelector } from '~/hooks/redux';
import { selectCurrentUserId } from '~/redux/selectors/authSelectors';
import { selectUser } from '~/redux/selectors/usersSelectors';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { EWorkRequestType } from '~/types/app';

interface IEditWorkRequestPopup {
  workRequest: IWorkRequest;
}

const EditWorkRequestPopup = ({ workRequest }: IEditWorkRequestPopup) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [visible, setVisible] = React.useState<boolean>(false);
  const {
    id,
    title,
    description,
    classification_author_risk,
    classification_otipb_risk,
    images,
    department_id,
  } = workRequest;
  const risk = classification_otipb_risk ? classification_otipb_risk : classification_author_risk;
  const currentUserId = useAppSelector(selectCurrentUserId);
  const user = useAppSelector(selectUser(currentUserId));
  const departaments = useSelector(selectDepartments);
  const companyOptions = useAppSelector(selectCompanyOptions);
  const isDepartmentPrombezMatch = departaments
    .map(({ id }) => id)
    .includes(companyOptions.prom_bez_department_id);
  const userDepartments = isDepartmentPrombezMatch
    ? departaments
    : departaments.filter(item =>
        [...(user?.departments_ids ?? []), ...(user?.view_departments_ids ?? [])].includes(item.id),
      );
  const [taskOpen, setTaskOpen] = useState<boolean>(false);
  const [task, setTask] = useState<any | undefined>(undefined);
  const [state, setState] = React.useState<IStateForm>({
    [NAMES_FIELD.TITLE]: title,
    [NAMES_FIELD.CLASSIFICATION_OTIPB_RISK]: risk,
    [NAMES_FIELD.DESCRIPTION]: description,
    [NAMES_FIELD.DEPARTMENT_ID]: department_id,
    [NAMES_FIELD.ATTACHMENT]: images,
  });
  const [riskChar, setRiskChar] = useState<'A' | 'B' | 'C' | 'D' | 'E'>(
    risk[0] as 'A' | 'B' | 'C' | 'D' | 'E',
  );
  const [riskNumber, setRiskNumber] = useState<'1' | '2' | '3' | '4' | '5'>(
    risk[1] as '1' | '2' | '3' | '4' | '5',
  );
  const riskChars = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
  };
  const riskNumbers = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  };

  const preparedDepartaments: ISelectOption = {};
  userDepartments.forEach(item => (preparedDepartaments[item.id] = item.name));

  const setValues = (value: any) => {
    setTimeout(() => {
      setState(prevState => {
        return {
          ...prevState,
          [NAMES_FIELD.IS_REPEAT]: false,
          [NAMES_FIELD.DESCRIPTION]: value?.description,
          [NAMES_FIELD.ATTACHMENT]: value?.images,
          [NAMES_FIELD.TITLE]: value?.title,
        };
      });
    }, 50);
  };

  useEffect(() => {
    setState({ ...state, [NAMES_FIELD.CLASSIFICATION_OTIPB_RISK]: `${riskChar}${riskNumber}` });
  }, [riskChar, riskNumber]);

  const handleClickBack = () => {
    dispatch(
      openDialog({
        data: {
          closable: true,
          dialogData: {
            title: 'Задача не сохранена',
            message: 'Вы уверены, что хотите вернуться назад без сохранения задачи?',
            confirm: {
              button: {
                text: 'Назад к заявке',
                transparent: true,
                background: COLORS.BLUE,
              },
              onConfirm: () => {
                setTaskOpen(false);
                setTask(undefined);
              },
            },
          },
        },
      }),
    );
  };

  const [, setErrors] = React.useState<{ [name: string]: string }>({});

  const handleOnClose = () => {
    dispatch(
      openPopup({
        name: POPUPS_NAME.VIEW_WORK_REQUEST_POPUP,
        data: {
          workRequest: workRequest,
        },
      }),
    );
  };

  const handelOnInput = (value: string | boolean | number, name: string) => {
    onInput({
      value,
      name,
      state,
      setState,
      setErrors,
    });
  };

  const handleEditWorkRequest = async () => {
    setLoading(true);

    const success = (text: string) => {
      toast.success(text);
      dispatch(closePopup());
    };

    const valueForm = {
      id: id,
      title: state[NAMES_FIELD.TITLE],
      description: state[NAMES_FIELD.DESCRIPTION],
      images: state[NAMES_FIELD.ATTACHMENT],
      classification_otipb_risk: state[NAMES_FIELD.CLASSIFICATION_OTIPB_RISK],
      department_id: state[NAMES_FIELD.DEPARTMENT_ID],
    };

    const result: any = dispatch(editWorkRequestPos(valueForm));
    if (result.payload.kind === 'ok') {
      success('Заявка изменена');
    } else errorHandler(result.payload);
    setLoading(false);
  };
  const handleRisksMatrixButtonClick = () => {
    setVisible(true);
  };
  return (
    <Popup aside className="taskPopup work-request-popup" onClose={handleOnClose}>
      <>
        {taskOpen && (
          <div className="users-add">
            <AddNewTask
              handleClickBack={handleClickBack}
              workRequestId={id}
              task={task}
              workRequestType={EWorkRequestType.E_POS}
            />
          </div>
        )}
        <AsidePopupHeader>
          <div className="work-request-popup__headerContainer">
            <h2 className="aside-popup__title aside-popup__title_notifications">
              Редактировать заявку для ПОС
            </h2>
          </div>
        </AsidePopupHeader>
        <AsidePopupBody>
          <div className="work-request-popup__bodyContainer">
            <div className="work-request__block">
              <Title
                onSelectItem={setValues}
                disabled
                value={state[NAMES_FIELD.TITLE]}
                onInput={event => handelOnInput(event.currentTarget.value, NAMES_FIELD.TITLE)}
              />
            </div>
            <div className="work-request__block">
              <div></div>
              <SelectsField
                name={NAMES_FIELD.DEPARTMENT_ID}
                label="Цех"
                options={preparedDepartaments}
                onChange={(value, name) => handelOnInput(value!.value, name)}
                defaultValue={state[NAMES_FIELD.DEPARTMENT_ID]}
              />
            </div>
            <div className="work-request-popup__block">
              <Risk riskCategory={state[NAMES_FIELD.CLASSIFICATION_OTIPB_RISK]} type="AlertBlock" />
              <AlertBlock>
                <p>
                  Классификация риска — это совокупность двух параметров: серьезности последствий и
                  вероятности их наступления. Выберите значения ниже, ответственно оценивая каждую
                  оценивая каждую ситуацию.
                </p>
                <br />
                <p>
                  Подробности в{' '}
                  <span
                    tabIndex={1}
                    role="button"
                    className="data"
                    onClick={handleRisksMatrixButtonClick}
                  >
                    матрице оценки рисков
                  </span>
                  .
                </p>
                <Image.PreviewGroup
                  preview={{
                    visible,
                    onVisibleChange: value => {
                      setVisible(value);
                    },
                  }}
                >
                  <Image style={{ display: 'none' }} src={matrixImage} />
                  <Image style={{ display: 'none' }} src={matrix2Image} />
                </Image.PreviewGroup>
              </AlertBlock>
              <div className="work-request-popup__propertyItemsGroupRow">
                <SelectsField
                  name={NAMES_FIELD.DEPARTMENT_ID}
                  label="Серьезность последствий"
                  options={riskChars}
                  onChange={value => setRiskChar(value!.value as 'A' | 'B' | 'C' | 'D' | 'E')}
                  defaultValue={riskChar}
                />
                <SelectsField
                  name={NAMES_FIELD.DEPARTMENT_ID}
                  label="Вероятность события"
                  options={riskNumbers}
                  onChange={value => setRiskNumber(value?.value as '1' | '2' | '3' | '4' | '5')}
                  defaultValue={riskNumber}
                />
              </div>
            </div>
            {/*type === 0 && (
              <>
                <div className="work-request-popup__propertyItemsGroup">
                  <div className="work-request-popup__propertyItem">
                    <span className="work-request-popup__propertyItem__title">
                      Начало выполнения работ
                    </span>
                    <span className="work-request-popup__propertyItem__value">{start_date}</span>
                  </div>
                  <div className="work-request-popup__propertyItem">
                    <span className="work-request-popup__propertyItem__title">
                      Окончание выполнения работ
                    </span>
                    <span className="work-request-popup__propertyItem__value">{end_date}</span>
                  </div>
                </div>
              </>
            )*/}

            <div className="work-request-popup__block">
              <div className="textarea-wrapper">
                <TextareasField
                  name={NAMES_FIELD.DESCRIPTION}
                  label="Описание"
                  disabled
                  placeholder="Введите описание задачи"
                  defaultValue={state[NAMES_FIELD.DESCRIPTION]}
                  onInput={(value, name) => handelOnInput(value, name)}
                />
              </div>
              <FileInput
                value={state[NAMES_FIELD.ATTACHMENT]}
                readonly
                onChange={e => {
                  setState(prevState => {
                    return {
                      ...prevState,
                      [NAMES_FIELD.ATTACHMENT]: e.target.value,
                    };
                  });
                }}
              />
            </div>

            <div className="work-request-popup__block">
              <Button
                text="Сохранить"
                background={COLORS.BLUE}
                disabled={loading}
                onClick={handleEditWorkRequest}
              />
            </div>
          </div>
        </AsidePopupBody>
      </>
    </Popup>
  );
};

export default EditWorkRequestPopup;

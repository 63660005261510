import { Skeleton } from 'antd';

const WorkRequestsSkeleton = () => {
  return (
    <div className="applications-skeleton">
      <div className="application">
        <Skeleton avatar paragraph={{ rows: 1 }} />
      </div>
      <div className="application application-skeleton">
        <Skeleton avatar paragraph={{ rows: 1 }} />
      </div>
      <div className="application application-skeleton">
        <Skeleton avatar paragraph={{ rows: 1 }} />
      </div>
    </div>
  );
};

export default WorkRequestsSkeleton;

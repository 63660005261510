import * as React from 'react';
import Popup from '~/components/popup/Popup';
import AsidePopupBody from '~/components/popup/AsidePopupBody';
import AsidePopupHeader from '~/components/popup/AsidePopupHeader';
import { FileInput } from '~/components';
import moment from 'moment';
import Api, { INewTask, ETaskPriority } from 'sb_manufacturing_front_api';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { createTask } from '~/redux/modules/tasksModule';
import { createTaskTimeless } from '~/redux/modules/tasksTimelessModule';
import {
  selectDay,
  selectDepartmentId,
  selectFilteredDepartmentIds,
  selectWorkShiftId,
} from '~/redux/selectors/stateSelectors';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { Calendar24 } from '~/assets/calendar24';
import { selectTasksTypes } from '~/redux/selectors/tasksTypesSelectors';
import { selectTasksGeoTypes } from '~/redux/selectors/tasksGeoTypesSelectors';
import { selectZones } from '~/redux/selectors/zonesSelectors';
import { DatePicker } from 'antd';
import { Title } from '~/containers/tasks/popups/task-popup/title';
import { AddUserPage } from '~/containers/tasks/popups/task-popup/add-user';
import { closePopup } from '~/redux/modules/popupModule';
import {
  createTaskTimelessTemplates,
  updateTaskTimelessTemplates,
} from '~/redux/modules/tasksTimelessTemplatesModule';
import {
  FORMAT_MOMENT,
  COLORS,
  PLACEHOLDER,
  NAMES_FIELD,
  ICON_TYPE,
  TYPE_COLOR,
} from '~/helpers/constants';
import getTimeOptions from '~/helpers/getTimeOptions';
import onInput from '~/utils/onInput';
import Button from '~/components/form/buttons/Button';
import SelectsField from '~/components/form/select/Select';
import CheckboxesField from '~/components/form/checkbox/Checkbox';
import TextareasField from '~/components/form/textarea/Textarea';
import UsersField from '~/components/form/users/Users';
import errorHandler from '~/utils/errorHandler';
import { PRIORITIES } from '~/helpers/getPriorityText';
import { prepareDateServerTime, getCompanyTime } from '~/helpers/convertToUnix';
import { selectDepartments } from '~/redux/selectors/departmentsSelectors';
import { selectProfile } from '~/redux/selectors/usersSelectors';

interface ICreateTaskPopup {
  template: any;
  type?: number;
}

const CreateTaskPopup = ({ template, type }: ICreateTaskPopup) => {
  const dispatch = useDispatch();

  const companyOptions = useSelector(selectCompanyOptions);
  const selectedDay = useSelector(selectDay);
  const departmentId = useSelector(selectDepartmentId);
  const departmentIds = useSelector(selectFilteredDepartmentIds);
  const workShiftId = useSelector(selectWorkShiftId);
  const taskTypes = useSelector(selectTasksTypes);
  const geoTypes = useSelector(selectTasksGeoTypes);
  const departments = useSelector(selectDepartments);
  const zones = useSelector(selectZones);
  const { RangePicker } = DatePicker;
  const getTime = getTimeOptions();
  const user = useSelector(selectProfile);

  const [tab, setTab] = React.useState<number>(type ?? 0);
  const [userOpen, setUserOpen] = React.useState<boolean>(false);
  const [days, setDays] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const preparedTaskTypes: ISelectOption = {};
  const preparedGeoTypes: ISelectOption = {};
  const preparedZones: ISelectOption = {};
  const preparedPriority: ISelectOption = { ...PRIORITIES };
  const preparedDepartments: ISelectOption = {};

  if (tab && preparedPriority.hasOwnProperty(2)) {
    delete preparedPriority['2'];
  }

  taskTypes.forEach(item => (preparedTaskTypes[item.id] = item.name));
  geoTypes.forEach(item => (preparedGeoTypes[item.id] = item.name));
  zones.forEach(item => (preparedZones[item.id] = item.name));
  departments.forEach(item => {
    if (user?.departments_ids.includes(item.id)) {
      preparedDepartments[item.id] = item.name;
    }
  });

  const [state, setState] = React.useState<IStateForm>({
    [NAMES_FIELD.PRIORITY]: 0,
    [NAMES_FIELD.SCHEDULE_TIME_HOURS]: 0,
    [NAMES_FIELD.SCHEDULE_TIME_MINUTES]: 0,
    [NAMES_FIELD.TYPE_ID]: taskTypes[0]?.id,
    [NAMES_FIELD.GEO_TYPE]: geoTypes[0]?.id,
    [NAMES_FIELD.GEO]: [],
    [NAMES_FIELD.DEPARTMENT_ID]:
      departmentIds[0] in preparedDepartments ? departmentIds[0] : departmentId,
    [NAMES_FIELD.AS_TEMPLATE]: false,
    [NAMES_FIELD.IS_REPEAT]: false,
    [NAMES_FIELD.USERS]: [],
    [NAMES_FIELD.DATE]: selectedDay,
  });
  const [, setErrors] = React.useState<{ [name: string]: string }>({});

  const handelOnInput = (value: string | boolean | number, name: string) => {
    onInput({
      value,
      name,
      state,
      setState,
      setErrors,
    });
  };

  React.useEffect(() => {
    setState(prevState => {
      return {
        ...prevState,
        [NAMES_FIELD.PRIORITY]: ETaskPriority.E_REGULAR,
      };
    });
  }, [tab]);

  React.useEffect(() => {
    setState(prevState => {
      return {
        ...prevState,
        [NAMES_FIELD.UNFIXED]: state[NAMES_FIELD.PRIORITY] === ETaskPriority.E_EMERGENCY,
      };
    });
  }, [state[NAMES_FIELD.PRIORITY]]);

  React.useEffect(() => {
    if (state[NAMES_FIELD.GEO_TYPE] === 0 || 2) {
      setState(prevState => {
        return {
          ...prevState,
          [NAMES_FIELD.GEO]: [],
        };
      });
    }
  }, [state[NAMES_FIELD.GEO_TYPE]]);

  const setValues = (value: any) => {
    const companyTime = getCompanyTime(companyOptions.time_zone);

    if (value?.duration) {
      setTab(1);
    }
    const scheduledTimeHours = moment(moment(value?.end_time).utc()).diff(
      moment(value?.begin_time).utc(),
      'hours',
    );
    const scheduledTimeMinutes = moment(moment(value?.end_time).utc()).diff(
      moment(value?.begin_time).utc(),
      'minutes',
    );
    const roundToTime = (num: string) => {
      const time = Math.ceil(parseInt(num) / 15);
      let resultTime = time * 15;

      if (resultTime === 60) {
        resultTime = 0;
      }
      return resultTime.toString().padStart(2, '0');
    };

    const timeFromTimeless: number = value?.duration ? Math.floor(value?.duration / 60 / 60) : 0;
    const timeToTimeless: number = value?.duration
      ? Math.floor(value?.duration / 60) - timeFromTimeless * 60
      : 0;

    setTimeout(() => {
      setState(prevState => {
        return {
          ...prevState,
          [NAMES_FIELD.TASK_ID]: value?.id,
          [NAMES_FIELD.PRIORITY]: value?.priority || 0,
          [NAMES_FIELD.UNFIXED]: value?.unfixed || false,
          [NAMES_FIELD.TYPE_ID]: value?.type_id || taskTypes[0]?.id,
          [NAMES_FIELD.GEO_TYPE]: value?.geo_type || 0,
          [NAMES_FIELD.GEO]: value?.geo || [],
          [NAMES_FIELD.IS_REPEAT]: false,
          [NAMES_FIELD.NEED_PHOTO_REPORT_BEFORE_START]: value?.need_photo_report_before_start,
          [NAMES_FIELD.NEED_PHOTO_REPORT_AFTER_COMPLETE]: value?.need_photo_report_after_complete,
          [NAMES_FIELD.IS_COMMENT]: tab ? value?.need_comment : value?.is_comment,
          [NAMES_FIELD.NEED_DEPARTMENT_TEST]: value?.need_department_test,
          [NAMES_FIELD.NEED_SUPERVISOR_TEST]: value?.need_supervisor_test,
          [NAMES_FIELD.NEED_PATH_PHOTO_REPORT]: value?.need_path_photo_report,
          [NAMES_FIELD.DESCRIPTION]: value?.description,
          [NAMES_FIELD.ATTACHMENT]: value?.images,
          [NAMES_FIELD.TITLE]: value?.title,
          [NAMES_FIELD.TEMPLATE_ID]: value?.id,
          [NAMES_FIELD.DEPARTMENT_ID]: value?.department_id,
          [NAMES_FIELD.USERS]:
            tab || value?.duration
              ? value?.tasks
                ? value?.tasks.map((item: INewTask) => item.user_id)
                : []
              : value?.users || [],
          [NAMES_FIELD.SCHEDULE_TIME_HOURS]: tab
            ? timeFromTimeless
            : scheduledTimeHours < 1
            ? 0
            : scheduledTimeHours,
          [NAMES_FIELD.SCHEDULE_TIME_MINUTES]: tab
            ? timeToTimeless
            : scheduledTimeMinutes - scheduledTimeHours * 60,
          [NAMES_FIELD.TIME_FROM]: value?.begin_time
            ? `${moment
                .utc(value.begin_time)
                .utcOffset(companyOptions.time_zone)
                .format(FORMAT_MOMENT.HH)}:${roundToTime(
                moment
                  .utc(value.begin_time)
                  .utcOffset(companyOptions.time_zone)
                  .format(FORMAT_MOMENT.MM),
              )}`
            : `${companyTime.format(FORMAT_MOMENT.HH)}:${roundToTime(
                companyTime.format(FORMAT_MOMENT.MM),
              )}`,
          [NAMES_FIELD.TIME_TO]: value?.end_time
            ? `${moment
                .utc(value?.end_time)
                .utcOffset(companyOptions.time_zone)
                .format(FORMAT_MOMENT.HH)}:${roundToTime(
                moment
                  .utc(value?.end_time)
                  .utcOffset(companyOptions.time_zone)
                  .format(FORMAT_MOMENT.MM),
              )}`
            : `${companyTime.format(FORMAT_MOMENT.HH)}:${roundToTime(
                companyTime.format(FORMAT_MOMENT.MM),
              )}`,
        };
      });
      if (tab || value?.duration) {
        setDays([
          moment(value?.start_date || moment().format('YYYY/MM/DD')),
          moment(value?.end_date || moment().format('YYYY/MM/DD')),
        ]);
      }
    }, 50);
  };

  const getBeginEndTime = (
    scheduledTimeHours: number,
    scheduledTimeMinutes: number,
    time_from?: string,
  ) => {
    const beginTime = time_from ? moment(`${state[NAMES_FIELD.DATE]} ${time_from}:00`) : moment();
    const endTime = moment(beginTime)
      .add(scheduledTimeHours, 'hours')
      .add(scheduledTimeMinutes, 'minutes');

    return { beginTime, endTime };
  };

  React.useEffect(() => {
    if (!state[NAMES_FIELD.TIME_FROM]) return;
    const [hours, minutes] = state[NAMES_FIELD.TIME_FROM].split(':');
    const endTime = moment(selectedDay)
      .utc(true)
      .add(hours, 'hours')
      .add(minutes, 'minutes')
      .add(state[NAMES_FIELD.SCHEDULE_TIME_HOURS], 'hours')
      .add(state[NAMES_FIELD.SCHEDULE_TIME_MINUTES], 'minutes');

    if (endTime.isValid()) {
      setTimeout(() => {
        setState(prevState => {
          return {
            ...prevState,
            [NAMES_FIELD.TIME_TO]: endTime.format(FORMAT_MOMENT.HHMM),
          };
        });
      }, 100);
    }
  }, [
    state[NAMES_FIELD.SCHEDULE_TIME_HOURS],
    state[NAMES_FIELD.SCHEDULE_TIME_MINUTES],
    state[NAMES_FIELD.TIME_FROM],
  ]);

  React.useEffect(() => {
    if (state[NAMES_FIELD.TIME_FROM]) {
      setTimeout(() => {
        setState(prevState => {
          return {
            ...prevState,
            [NAMES_FIELD.TIME_TO]: moment(state[NAMES_FIELD.TIME_FROM], FORMAT_MOMENT.HHMM)
              .add(state[NAMES_FIELD.SCHEDULE_TIME_HOURS], 'hours')
              .add(state[NAMES_FIELD.SCHEDULE_TIME_MINUTES], 'minutes')
              .format(FORMAT_MOMENT.HHMM),
          };
        });
      }, 50);
    }
  }, [
    state[NAMES_FIELD.SCHEDULE_TIME_HOURS],
    state[NAMES_FIELD.SCHEDULE_TIME_MINUTES],
    state[NAMES_FIELD.TIME_FROM],
  ]);

  React.useEffect(() => {
    if (template) {
      setValues(template);
    }
  }, [template]);

  const handleRemoveUser = (id: number) => {
    setState(prevState => {
      return {
        ...prevState,
        [NAMES_FIELD.USERS]: state[NAMES_FIELD.USERS].filter((u: number) => u !== id),
      };
    });
  };

  const handleAddUser = (id: number) => {
    if (!state[NAMES_FIELD.USERS].includes(id)) {
      setState(prevState => {
        return {
          ...prevState,
          [NAMES_FIELD.USERS]: [...state[NAMES_FIELD.USERS], id],
        };
      });
    }
  };

  const handleCreateTask = async () => {
    setLoading(true);

    const success = (text: string) => {
      toast.success(text);
      dispatch(closePopup());
    };

    const { beginTime, endTime } = getBeginEndTime(
      state[NAMES_FIELD.SCHEDULE_TIME_HOURS],
      state[NAMES_FIELD.SCHEDULE_TIME_MINUTES],
      state[NAMES_FIELD.TIME_FROM],
    );

    if (tab === 0) {
      const valueForm = {
        task_id: state[NAMES_FIELD.TASK_ID],
        task_template_id: state[NAMES_FIELD.TEMPLATE_ID],
        title: state[NAMES_FIELD.TITLE],
        description: state[NAMES_FIELD.DESCRIPTION],
        images: state[NAMES_FIELD.ATTACHMENT],
        is_comment: state[NAMES_FIELD.IS_COMMENT],
        need_path_photo_report: state[NAMES_FIELD.NEED_PATH_PHOTO_REPORT],
        need_supervisor_test: state[NAMES_FIELD.NEED_SUPERVISOR_TEST],
        need_department_test: state[NAMES_FIELD.NEED_DEPARTMENT_TEST],
        need_photo_report_before_start: state[NAMES_FIELD.NEED_PHOTO_REPORT_BEFORE_START],
        need_photo_report_after_complete: state[NAMES_FIELD.NEED_PHOTO_REPORT_AFTER_COMPLETE],
        is_everyday: state[NAMES_FIELD.IS_REPEAT],
        users: state[NAMES_FIELD.USERS],
        priority: state[NAMES_FIELD.PRIORITY],
        type_id: Number(state[NAMES_FIELD.TYPE_ID]),
        geo_type: Number(state[NAMES_FIELD.GEO_TYPE]),
        geo: state[NAMES_FIELD.GEO],
        begin_time: prepareDateServerTime(
          beginTime.format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
          companyOptions.time_zone,
        ),
        end_time: prepareDateServerTime(
          endTime.format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
          companyOptions.time_zone,
        ),
        department_id: state[NAMES_FIELD.DEPARTMENT_ID],
        working_shift_id: workShiftId,
        unfixed: state[NAMES_FIELD.UNFIXED],
      };

      if (!state[NAMES_FIELD.AS_TEMPLATE]) {
        const res: any = await dispatch(createTask(valueForm));
        if (res.payload.kind === 'ok') {
          success('Задача сохранена');
        } else errorHandler(res.payload);
      } else {
        if (template?.id) {
          const result = await Api.updateTaskTemplate(valueForm);
          if (result.kind === 'ok') {
            success('Шаблон обновлён');
          } else errorHandler(result);
        } else {
          const result = await Api.createTaskTemplate(valueForm);
          if (result.kind === 'ok') {
            success('Шаблон сохранён');
          } else errorHandler(result);
        }
      }
    } else {
      const valueForm = {
        start_date: days[0]
          ? moment(days[0])
              .subtract(companyOptions.time_zone, 'hour')
              .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS)
          : '',
        end_date: days[1]
          ? moment(days[1])
              .subtract(companyOptions.time_zone, 'hour')
              .add('days', 1)
              .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS)
          : '',
        task_template_id: state[NAMES_FIELD.TEMPLATE_ID],
        title: state[NAMES_FIELD.TITLE],
        description: state[NAMES_FIELD.DESCRIPTION],
        images: state[NAMES_FIELD.ATTACHMENT],
        need_path_photo_report: state[NAMES_FIELD.NEED_PATH_PHOTO_REPORT],
        need_supervisor_test: state[NAMES_FIELD.NEED_SUPERVISOR_TEST],
        need_department_test: state[NAMES_FIELD.NEED_DEPARTMENT_TEST],
        need_photo_report_before_start: state[NAMES_FIELD.NEED_PHOTO_REPORT_BEFORE_START],
        need_photo_report_after_complete: state[NAMES_FIELD.NEED_PHOTO_REPORT_AFTER_COMPLETE],
        need_comment: state[NAMES_FIELD.IS_COMMENT],
        duration:
          (Number(state[NAMES_FIELD.SCHEDULE_TIME_MINUTES]) +
            Number(state[NAMES_FIELD.SCHEDULE_TIME_HOURS]) * 60) *
          60,
        users_ids: state[NAMES_FIELD.USERS],
        priority: state[NAMES_FIELD.PRIORITY],
        type_id: Number(state[NAMES_FIELD.TYPE_ID]),
        geo_type: Number(state[NAMES_FIELD.GEO_TYPE]),
        geo: state[NAMES_FIELD.GEO],
        department_id: state[NAMES_FIELD.DEPARTMENT_ID],
      };

      if (!state[NAMES_FIELD.AS_TEMPLATE]) {
        const result: any = await dispatch(createTaskTimeless(valueForm));
        if (result.payload.kind === 'ok') {
          success('Задача без времени создана');
        } else errorHandler(result.payload);
      } else {
        if (template?.id) {
          const result: any = await dispatch(
            updateTaskTimelessTemplates({
              id: state[NAMES_FIELD.TEMPLATE_ID],
              ...valueForm,
            }),
          );
          if (result.payload.kind === 'ok') {
            success('Шаблон задачи без времени обновлён');
          } else errorHandler(result.payload);
        } else {
          const result: any = await dispatch(createTaskTimelessTemplates(valueForm));
          if (result.payload.kind === 'ok') {
            success('Шаблон задачи без времени сохранён');
          } else errorHandler(result.payload);
        }
      }
    }

    setLoading(false);
  };

  const clearDuplicates = (geoList: number[]) => {
    let lastGeo = null;
    const filteredArray = [];

    for (let i = 0; i < geoList.length; i++) {
      if (geoList.length && geoList[i] === lastGeo) {
      } else {
        filteredArray.push(geoList[i]);
        lastGeo = geoList[i];
      }
    }

    return filteredArray;
  };

  const removeGeoZone = (key: number) => {
    if (state[NAMES_FIELD.GEO].length) {
      let newArray = [...state[NAMES_FIELD.GEO]];
      newArray.splice(key, 1);

      newArray = clearDuplicates(newArray);

      setState(prevState => {
        return {
          ...prevState,
          [NAMES_FIELD.GEO]: newArray,
        };
      });
    }
  };

  const addZone = (value: number) => {
    if (value > 0) {
      let newArray = state[NAMES_FIELD.GEO].length ? [...state[NAMES_FIELD.GEO]] : [];
      newArray.push(value);

      newArray = clearDuplicates(newArray);

      setState(prevState => {
        return {
          ...prevState,
          [NAMES_FIELD.GEO]: newArray,
        };
      });
    }
  };

  const handleCalendarChange = (value: any) => {
    setDays([]);
    const newDate: Array<any> = [];
    if (value) {
      value.forEach((date: any) => {
        if (date) {
          newDate.push(moment(moment(date).format('YYYY/MM/DD')));
        }
      });
      setDays(newDate);
    }
  };

  const open = (value: boolean) => {
    setUserOpen(value);
  };

  return (
    <Popup aside className="taskPopup">
      <>
        {userOpen && (
          <div className="users-add">
            <AddUserPage
              onAdd={handleAddUser}
              onRemove={handleRemoveUser}
              users={state[NAMES_FIELD.USERS]}
              setPage={setUserOpen}
              filters={{ department_id: state[NAMES_FIELD.DEPARTMENT_ID] }}
            />
          </div>
        )}
        <AsidePopupHeader />
        <AsidePopupBody>
          <div>
            {companyOptions.use_task_timeless ? (
              <div className="tabs-popup">
                <div
                  className={`tabs-popup__tab ${tab === 0 ? 'active' : ''}`}
                  onClick={() => setTab(0)}
                >
                  Задача в расписание
                </div>
                <div
                  className={`tabs-popup__tab ${tab === 1 ? 'active' : ''}`}
                  onClick={() => setTab(1)}
                >
                  Задача без времени
                </div>
              </div>
            ) : null}
            <Title
              onSelectItem={setValues}
              value={state[NAMES_FIELD.TITLE]}
              onInput={event => handelOnInput(event.currentTarget.value, NAMES_FIELD.TITLE)}
            />
          </div>
          <div className="taskPopup__form">
            <div className="taskPopup__block">
              <CheckboxesField
                name={NAMES_FIELD.AS_TEMPLATE}
                label="Использовать задачу как шаблон"
                defaultValue={state[NAMES_FIELD.AS_TEMPLATE]}
                onChange={(value, name) => {
                  handelOnInput(value, name);

                  if (!value) {
                    handelOnInput(false, NAMES_FIELD.IS_REPEAT);
                  }
                }}
              />
            </div>
            <div className="taskPopup__block">
              <SelectsField
                name={NAMES_FIELD.PRIORITY}
                classNameWrapper="selectPriority"
                label="Приоритет"
                options={preparedPriority}
                onChange={(value, name) => handelOnInput(Number(value!.value), name)}
                defaultValue={state[NAMES_FIELD.PRIORITY]}
                iconName={ICON_TYPE.PRIORITY}
                typeColor={TYPE_COLOR.PRIORITY}
              />
            </div>
            <div className="taskPopup__block">
              <h3 className="time-inputs__title">Дата</h3>
              <DatePicker
                className={''}
                name={NAMES_FIELD.DATE}
                locale={locale}
                placeholder={'Дата'}
                disabledDate={date => date.isBefore(moment().subtract(1, 'days'))}
                onChange={value => {
                  handelOnInput(
                    value ? value.format(FORMAT_MOMENT.DASH_YYYYMMDD) : '',
                    NAMES_FIELD.DATE,
                  );
                }}
                allowClear={true}
                format={FORMAT_MOMENT.DDMMYYYY}
                value={state[NAMES_FIELD.DATE] ? moment(state[NAMES_FIELD.DATE]) : null}
                getPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
                dropdownAlign={{ useCssBottom: true }}
              />
            </div>
            <div className="taskPopup__block">
              <div className="taskPopup__times">
                <div className="time-inputs">
                  <h3 className="time-inputs__title">
                    {tab ? 'Рекомендуемое время' : 'Регламентное время'}
                  </h3>
                  <div className="time-inputs__item">
                    <SelectsField
                      name={NAMES_FIELD.SCHEDULE_TIME_HOURS}
                      options={getTime.hours}
                      onChange={(value, name) => handelOnInput(value!.value, name)}
                      defaultValue={state[NAMES_FIELD.SCHEDULE_TIME_HOURS]}
                      hideIndicator
                    />
                  </div>
                  <div className="time-inputs__item">
                    <SelectsField
                      name={NAMES_FIELD.SCHEDULE_TIME_MINUTES}
                      options={getTime.minutes}
                      onChange={(value, name) => handelOnInput(value!.value, name)}
                      defaultValue={state[NAMES_FIELD.SCHEDULE_TIME_MINUTES]}
                      hideIndicator
                    />
                  </div>
                </div>
                {tab ? (
                  <div className="time-inputs">
                    <h3 className="time-inputs__title">Период выполнения</h3>
                    <RangePicker
                      locale={locale}
                      allowEmpty={[false, true]}
                      placeholder={['ДД.ММ.ГГГГ', 'ДД.ММ.ГГГГ']}
                      separator={<span>→</span>}
                      suffixIcon={<Calendar24 />}
                      format={[FORMAT_MOMENT.DDMMYYYY]}
                      value={days}
                      disabledDate={date => date.isBefore(moment().add('days', -1))}
                      getPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
                      onChange={handleCalendarChange}
                    />
                  </div>
                ) : (
                  <div className="time-inputs">
                    <h3 className="time-inputs__title">Время выполнения</h3>
                    <div className="time-inputs__item">
                      <SelectsField
                        name={NAMES_FIELD.TIME_FROM}
                        options={getTime.time}
                        onChange={(value, name) => handelOnInput(value!.value, name)}
                        defaultValue={state[NAMES_FIELD.TIME_FROM]}
                        hideIndicator
                      />
                    </div>
                    <div className="time-inputs__item">
                      <SelectsField
                        name={NAMES_FIELD.TIME_TO}
                        options={getTime.time}
                        onChange={(value, name) => handelOnInput(value!.value, name)}
                        defaultValue={state[NAMES_FIELD.TIME_TO]}
                        disabled
                      />
                    </div>
                  </div>
                )}
              </div>
              {tab === 0 && (
                <CheckboxesField
                  name={NAMES_FIELD.UNFIXED}
                  className="taskPopup__timesCheckbox"
                  label="Позволять начинать вне расписания"
                  defaultValue={state[NAMES_FIELD.UNFIXED]}
                  onChange={(value, name) => {
                    handelOnInput(value, name);
                  }}
                  disabled={state[NAMES_FIELD.PRIORITY] === ETaskPriority.E_EMERGENCY}
                />
              )}
            </div>
            <div className="taskPopup__block">
              <div className="textarea-wrapper">
                <TextareasField
                  name={NAMES_FIELD.DESCRIPTION}
                  label="Описание"
                  placeholder="Введите описание задачи"
                  defaultValue={state[NAMES_FIELD.DESCRIPTION]}
                  onInput={(value, name) => handelOnInput(value, name)}
                />
              </div>
              <FileInput
                value={state[NAMES_FIELD.ATTACHMENT]}
                onChange={e => {
                  setState(prevState => {
                    return {
                      ...prevState,
                      [NAMES_FIELD.ATTACHMENT]: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className="taskPopup__block">
              <SelectsField
                name={NAMES_FIELD.DEPARTMENT_ID}
                label="Цех"
                options={preparedDepartments}
                onChange={(value, name) => handelOnInput(value!.value, name)}
                defaultValue={state[NAMES_FIELD.DEPARTMENT_ID]}
              />
            </div>

            <div className="taskPopup__block">
              <UsersField
                onChange={handleRemoveUser}
                value={state[NAMES_FIELD.USERS]}
                onGoToSearch={value => open(value ?? false)}
                isTemplate={state[NAMES_FIELD.AS_TEMPLATE]}
              />
            </div>
            <div className="taskPopup__block">
              <SelectsField
                name={NAMES_FIELD.TYPE_ID}
                label="Тип задачи"
                options={preparedTaskTypes}
                onChange={(value, name) => handelOnInput(value!.value, name)}
                defaultValue={state[NAMES_FIELD.TYPE_ID]}
              />
            </div>
            {!!geoTypes.length && companyOptions.use_geo && (
              <div className="taskPopup__block">
                <SelectsField
                  name={NAMES_FIELD.GEO_TYPE}
                  label="Гео"
                  options={preparedGeoTypes}
                  defaultValue={state[NAMES_FIELD.GEO_TYPE]}
                  onChange={(value, name) => handelOnInput(value!.value, name)}
                />
              </div>
            )}
            {Number(state[NAMES_FIELD.GEO_TYPE]) === 1 && (
              <div className="taskPopup__block">
                <SelectsField
                  name={NAMES_FIELD.GEO}
                  label="Зона"
                  options={preparedZones}
                  placeholder={PLACEHOLDER.SELECT_ZONE}
                  onChange={(value, name) => {
                    setState(prevState => {
                      return {
                        ...prevState,
                        [name]: [Number(value!.value)],
                      };
                    });
                  }}
                />
              </div>
            )}
            {Number(state[NAMES_FIELD.GEO_TYPE]) === 2 && (
              <div className="taskPopup__block">
                <div className="route-zones">
                  <h3 className="input-block__title">Зоны</h3>
                  <div
                    className={
                      state[NAMES_FIELD.GEO].length
                        ? 'route-zones__route'
                        : 'route-zones__route empty'
                    }
                  >
                    {state[NAMES_FIELD.GEO].length
                      ? state[NAMES_FIELD.GEO].map((geo: number, key: number) => (
                          <p className="route-zones__zone">
                            {zones && geo
                              ? zones.filter(zone => Number(zone?.id) === geo)[0]?.name
                              : ''}
                            <span onClick={() => removeGeoZone(key)}>удалить</span>
                          </p>
                        ))
                      : null}
                    <SelectsField
                      options={preparedZones}
                      placeholder={PLACEHOLDER.ADD_ZONE}
                      onChange={(value: ISelectValue) => addZone(Number(value!.value))}
                    />
                  </div>
                  <CheckboxesField
                    name={NAMES_FIELD.NEED_PATH_PHOTO_REPORT}
                    label="Требует фотоотчет по маршруту"
                    defaultValue={state[NAMES_FIELD.NEED_PATH_PHOTO_REPORT]}
                    onChange={(value, name) => handelOnInput(value, name)}
                  />
                </div>
              </div>
            )}
            <div className="taskPopup__block">
              <CheckboxesField
                name={NAMES_FIELD.NEED_SUPERVISOR_TEST}
                label="Требует проверки начальника смены"
                defaultValue={state[NAMES_FIELD.NEED_SUPERVISOR_TEST]}
                onChange={(value, name) => handelOnInput(value, name)}
              />
              <CheckboxesField
                name={NAMES_FIELD.NEED_DEPARTMENT_TEST}
                label="Требует проверки начальника участка"
                defaultValue={state[NAMES_FIELD.NEED_DEPARTMENT_TEST]}
                onChange={(value, name) => handelOnInput(value, name)}
              />
              <CheckboxesField
                name={NAMES_FIELD.IS_COMMENT}
                label="Требует комментария работника"
                defaultValue={state[NAMES_FIELD.IS_COMMENT]}
                onChange={(value, name) => handelOnInput(value, name)}
              />
              <CheckboxesField
                name={NAMES_FIELD.NEED_PHOTO_REPORT_BEFORE_START}
                label="Требует фотоотчет перед началом"
                defaultValue={state[NAMES_FIELD.NEED_PHOTO_REPORT_BEFORE_START]}
                onChange={(value, name) => handelOnInput(value, name)}
              />
              <CheckboxesField
                name={NAMES_FIELD.NEED_PHOTO_REPORT_AFTER_COMPLETE}
                label="Требует фоотчёт при завершении"
                defaultValue={state[NAMES_FIELD.NEED_PHOTO_REPORT_AFTER_COMPLETE]}
                onChange={(value, name) => handelOnInput(value, name)}
              />
              {tab === 0 && (
                <CheckboxesField
                  name={NAMES_FIELD.IS_REPEAT}
                  label="Повторять ежесменно"
                  defaultValue={state[NAMES_FIELD.IS_REPEAT]}
                  disabled={state[NAMES_FIELD.AS_TEMPLATE]}
                  onChange={(value, name) => handelOnInput(value, name)}
                />
              )}
            </div>
            <div className="taskPopup__block">
              <Button
                text="Сохранить"
                background={COLORS.BLUE}
                disabled={loading}
                onClick={handleCreateTask}
              />
            </div>
          </div>
        </AsidePopupBody>
      </>
    </Popup>
  );
};

export default CreateTaskPopup;

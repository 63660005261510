import { useState } from 'react';
import {
  EWorkRequestStatus,
  IApproveWorkRequestForm,
  INewTaskLog,
  IWorkRequest,
  INewTask,
} from 'sb_manufacturing_front_api';
import Button from '~/components/form/buttons/Button';
import AsidePopupBody from '~/components/popup/AsidePopupBody';
import AsidePopupHeader from '~/components/popup/AsidePopupHeader';

import Popup from '~/components/popup/Popup';
import Status from '~/components/status/Status';
import { TaskStatus } from '~/components/task-status';
import { BUTTON_TYPE, COLORS, ICON_TYPE } from '~/helpers/constants';
import AddNewTask from './AddNewTask';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '~/redux/modules/dialogPopupModule';
import { useAppSelector } from '~/hooks/redux';
import { getLocalTimeFormat } from '~/helpers/convertToUnix';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import Risk from '~/components/status/Risk';
import * as React from 'react';
import { selectFilteredDepartmentIds } from '~/redux/selectors/stateSelectors';
import { selectDepartments } from '~/redux/selectors/departmentsSelectors';
import { TaskHistory } from '~/containers/tasks/popups/task-popup/history';
import * as R from 'ramda';
import { closePopup, openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import { FileInput } from '~/components';
import { toast } from 'react-toastify';
import { approveWorkRequest } from '~/redux/modules/workRequestsModule';
import errorHandler from '~/utils/errorHandler';
import { EWorkRequestType } from '~/types/app';

interface IEditWorkRequestPopup {
  workRequest: IWorkRequest;
}

const ViewWorkRequestPopup = ({ workRequest }: IEditWorkRequestPopup) => {
  const dispatch = useDispatch();
  const departmentsIds = useSelector(selectFilteredDepartmentIds);
  const risk = workRequest.classification_otipb_risk
    ? workRequest.classification_otipb_risk
    : workRequest.classification_author_risk;
  const sortByDate = R.sortWith([R.ascend(R.prop('date'))]);
  const companyOptions = useAppSelector(selectCompanyOptions);
  const { id, status, title, description, images, is_accepted, tasks } = workRequest;
  const departments = useSelector(selectDepartments);
  const [taskOpen, setTaskOpen] = useState<boolean>(false);
  const [task, setTask] = useState<INewTask | undefined>(undefined);

  const preparedDepartments: ISelectOption = {};
  departments.forEach(item => (preparedDepartments[item.id] = item.name));
  const isDepartmentMatch = departmentsIds.includes(workRequest.department_id);
  const isDepartmentPrombezMatch = departmentsIds.includes(companyOptions.prom_bez_department_id);

  const handleClickTaskButton = () => {
    setTaskOpen(true);
  };

  const handleClickBack = () => {
    dispatch(
      openDialog({
        data: {
          closable: true,
          dialogData: {
            title: 'Задача не сохранена',
            message: 'Вы уверены, что хотите вернуться назад без сохранения задачи?',
            confirm: {
              button: {
                text: 'Назад к заявке',
                transparent: true,
                background: COLORS.BLUE,
              },
              onConfirm: () => {
                setTaskOpen(false);
                setTask(undefined);
              },
            },
          },
        },
      }),
    );
  };

  const handleTaskHistoryBack = () => {
    dispatch(closePopup());
    setTimeout(() => {
      dispatch(
        openPopup({
          name: POPUPS_NAME.VIEW_WORK_REQUEST_POPUP,
          data: { workRequest: workRequest },
        }),
      );
    }, 10);
  };

  const handleClickEdit = () => {
    setTimeout(() => {
      dispatch(
        openPopup({
          name: POPUPS_NAME.EDIT_WORK_REQUEST_POPUP,
          data: {
            workRequest: workRequest,
          },
        }),
      );
    }, 10);
  };

  const handleClickCancel = () => {
    setTimeout(() => {
      dispatch(
        openPopup({
          name: POPUPS_NAME.WORK_REQUEST_POPUP,
          data: {
            type: 'cancel',
            workRequest: workRequest,
          },
        }),
      );
    }, 10);
  };
  const handleClickClose = () => {
    setTimeout(() => {
      dispatch(
        openPopup({
          name: POPUPS_NAME.WORK_REQUEST_POPUP,
          data: {
            type: 'close',
            workRequest: workRequest,
          },
        }),
      );
    }, 10);
  };
  const handleClickAccept = () => {
    setTimeout(() => {
      dispatch(
        openDialog({
          data: {
            closable: true,
            dialogData: {
              title: 'Принять заявку',
              message: `Вы действительно хотите принять заявку “${title}”`,
              confirm: {
                button: {
                  text: 'Принять',
                  background: COLORS.BLUE,
                },
                onConfirm: async () => {
                  const success = (text: string) => {
                    toast.success(text);
                    dispatch(closePopup());
                  };
                  const valueForm: IApproveWorkRequestForm = {
                    id: workRequest.id,
                    approve: true,
                  };
                  const res: any = await dispatch(approveWorkRequest(valueForm));
                  if (res.payload.kind && res.payload.kind === 'ok') {
                    dispatch(closePopup());
                    success('Заявка закрыта');
                  } else errorHandler(res.payload);
                },
              },
            },
          },
        }),
      );
    }, 10);
  };
  const handleClickReject = () => {
    setTimeout(() => {
      dispatch(
        openPopup({
          name: POPUPS_NAME.WORK_REQUEST_POPUP,
          data: {
            type: 'reject',
            workRequest: workRequest,
          },
        }),
      );
    }, 10);
  };
  const handleClickChangeDepartment = () => {
    setTimeout(() => {
      dispatch(
        openPopup({
          name: POPUPS_NAME.WORK_REQUEST_POPUP,
          data: {
            type: 'department',
            workRequest: workRequest,
          },
        }),
      );
    }, 10);
  };
  const handleClickTask = (task: INewTask) => {
    setTask(task);
    setTaskOpen(true);
  };

  const isCancelClosedMatch =
    status === EWorkRequestStatus.E_CANCELED || status === EWorkRequestStatus.E_CLOSED;

  return (
    <Popup aside className="taskPopup work-request-popup">
      <>
        {taskOpen && (
          <div className="users-add">
            <AddNewTask
              handleClickBack={handleClickBack}
              workRequestId={id}
              task={task}
              workRequestType={EWorkRequestType.E_POS}
            />
          </div>
        )}
        <AsidePopupHeader>
          <div className="work-request-popup__headerContainer">
            <Status title={status} />
            <h2 className="aside-popup__title aside-popup__title_notifications">{title}</h2>
          </div>
        </AsidePopupHeader>
        <AsidePopupBody>
          <div className="work-request-popup__bodyContainer">
            <div className="work-request-popup__tasksContainer">
              <span className="work-request-popup__tasksContainer__title">Задачи</span>
              {tasks.length > 0 && (
                <div className="work-request-popup__tasksContainer__tasks">
                  {tasks.map(task => (
                    <div className="work-request-popup__tasksContainer__task">
                      <div className="work-request-popup__tasksContainer__task__avatar">ФИ</div>
                      <div className="work-request-popup__tasksContainer__task__taskBody">
                        <div className="work-request-popup__tasksContainer__task__extraData">
                          <TaskStatus status={task?.status} />
                          <span className="work-request-popup__tasksContainer__task__date">
                            {`${getLocalTimeFormat(
                              task.begin_time,
                              companyOptions.time_zone,
                              'DD.MM.YYYY',
                            )}, ${task.working_shift_name}`}
                          </span>
                        </div>
                        <div className="work-request-popup__tasksContainer__task__title">
                          <span>{task.title}</span>
                        </div>
                      </div>
                      <Button
                        transparent
                        type={BUTTON_TYPE.ICON}
                        icon
                        iconType={ICON_TYPE.ARROW_NEXT}
                        onClick={() => handleClickTask(task)}
                      />
                    </div>
                  ))}
                </div>
              )}

              <Button
                className="work-request-popup__tasksContainer__button"
                text="Создать задачу"
                type={BUTTON_TYPE.ICON}
                iconType={ICON_TYPE.CREATE}
                icon
                transparent
                disabled={
                  status === EWorkRequestStatus.E_CLOSED || status === EWorkRequestStatus.E_CANCELED
                }
                onClick={handleClickTaskButton}
              />
            </div>
            <div className="work-request__block viewTaskPopup__departmentBlock">
              <div className="viewTaskPopup__blockTitle">Цех</div>
              <div className="work-request-popup__propertyItem__value">
                {departments.find(({ id }) => id === workRequest.department_id)?.name}
              </div>
              {(!isDepartmentMatch ||
                workRequest.department_id !== companyOptions.prom_bez_department_id) && (
                <div className="viewTaskPopup__departmentBlockButton">
                  <Button
                    text="Цех указан неверно"
                    transparent
                    background={COLORS.BLUE}
                    onClick={handleClickChangeDepartment}
                  />
                </div>
              )}
            </div>
            <div className="work-request-popup__block">
              {risk && <Risk riskCategory={risk} type="AlertBlock" />}
            </div>
            <TaskHistory
              data={(((sortByDate(workRequest?.logs) as unknown) as INewTaskLog[]) || []).slice(
                0,
                3,
              )}
              inside
              onBack={handleTaskHistoryBack}
              task={workRequest}
            />

            <div className="work-request-popup__propertyItem">
              <span className="work-request-popup__propertyItem__title">Описание</span>
              <span className="work-request-popup__propertyItem__value">{description}</span>
              <FileInput className="viewTaskPopup__files" value={images} readonly />
            </div>

            {(isDepartmentPrombezMatch || (isDepartmentMatch && !is_accepted)) && (
              <div className="taskPopup__block taskPopup__buttonsBlock">
                <div className="taskPopup__buttonBlock">
                  {status !== EWorkRequestStatus.E_CANCELED &&
                    status !== EWorkRequestStatus.E_CLOSED && (
                      <>
                        {isDepartmentPrombezMatch && (
                          <Button
                            text="Ред"
                            border
                            background={COLORS.BLUE}
                            onClick={handleClickEdit}
                          />
                        )}
                        <Button
                          text="Отменить"
                          border
                          background={COLORS.BLUE}
                          onClick={handleClickCancel}
                        />
                      </>
                    )}
                  {isDepartmentPrombezMatch &&
                    (status === EWorkRequestStatus.E_CANCELED ||
                      status === EWorkRequestStatus.E_CLOSED) && (
                      <Button
                        text="Отклонить"
                        border
                        background={COLORS.BLUE}
                        onClick={handleClickReject}
                      />
                    )}
                </div>
                {!isCancelClosedMatch && (
                  <Button text="Закрыть" background={COLORS.BLUE} onClick={handleClickClose} />
                )}
                {isDepartmentPrombezMatch && isCancelClosedMatch && (
                  <Button text="Принять" background={COLORS.BLUE} onClick={handleClickAccept} />
                )}
              </div>
            )}
          </div>
        </AsidePopupBody>
      </>
    </Popup>
  );
};

export default ViewWorkRequestPopup;

import * as React from 'react';

const iconv = require('iconv-lite');
import moment from 'moment';
import { ReportsTable } from './list-table';
import { ReportsFilters } from './Filters';

import { useAppDispatch, useAppSelector } from '~/hooks/redux';
import {
  selectDataDepartments,
  selectDepartmentsByIdsFilter,
} from '~/redux/selectors/departmentsSelectors';
import { selectDataProfessions } from '~/redux/selectors/professionsSelectors';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { setReportsFilters, setReportsFilterItem } from '~/redux/reducers/ReportsFiltersSlice';
import { fetchTasksReport } from '~/redux/modules/tasksReportsModule';
import { fetchHelmetReport } from '~/redux/modules/helmetReportsModule';
import Api, {
  EIncidentType,
  IIncidentsListFilter,
  IWorkRequestsFilter,
  IZoneEntranceDataFilter,
} from 'sb_manufacturing_front_api';
import fileDownload from 'js-file-download';
import { prepareDateServerTime } from '~/helpers/convertToUnix';
import { selectCurrentUserId, selectUserRoleCanEdit } from '~/redux/selectors/authSelectors';
import { selectUser, selectDataUsers } from '~/redux/selectors/usersSelectors';
import { today } from '~/redux/modules/periodsModule';
import { updateColumns } from '~/redux/modules/reportsColumnsModule';
import { selectDataWorkingShifts } from '~/redux/selectors/workingShiftsSelectors';
import { selectDataZones } from '~/redux/selectors/zonesSelectors';
import { selectDataTaskTypes } from '~/redux/selectors/tasksTypesSelectors';
import { selectDataTaskStatuses } from '~/redux/selectors/tasksStatusesSelectors';
import { fetchShiftsReport } from '~/redux/modules/shiftsReportsModule';
import Pagination from '~/components/pagination/Pagination';
import SearchInput from '~/components/SearchInput';
import {
  BUTTON_TYPE,
  ICON_TYPE,
  COLORS,
  TYPE_ROLE,
  FORMAT_MOMENT,
  DATA_INCIDENTS_TYPES,
} from '~/helpers/constants';
import Button from '~/components/form/buttons/Button';
import { titlesMap } from '~/utils/titles';
import getDatesIsSame from '~/helpers/getDatesIsSame';
import errorHandler from '~/utils/errorHandler';
import { fetchIncidentsList } from '~/redux/modules/incidentsListModule';
import { fetchZoneEntranceReports } from '~/redux/modules/zoneEntranceReportsModule';
import { fetchWorkRequestsReport } from '~/redux/modules/workRequestReportModule';

const ReportsScreen = () => {
  const dispatch = useAppDispatch();
  const momentFormat = 'YYYY/MM/DD';

  const dataProfessions = useAppSelector(selectDataProfessions);
  const dataDepartments = useAppSelector(selectDataDepartments);
  const userId = useAppSelector(selectCurrentUserId);
  const currentUser = useAppSelector(selectUser(userId));
  const dataDepartmentsView = useAppSelector(
    selectDepartmentsByIdsFilter(currentUser?.departments_ids ?? []),
  );
  const userRoleCanEdit = useAppSelector(selectUserRoleCanEdit);
  const companyOptions = useAppSelector(selectCompanyOptions);
  const dataWorkingShifts = useAppSelector(selectDataWorkingShifts);
  const users = useAppSelector(selectDataUsers);
  const dataZones = useAppSelector(selectDataZones);
  const dataTaskTypes = useAppSelector(selectDataTaskTypes);
  const dataTaskStatuses = useAppSelector(selectDataTaskStatuses);
  const { reportsFilters } = useAppSelector(state => state.reportsFilters);
  const { totalCount: reportsTaskTotalCount } = useAppSelector(state => state.reportsTask);
  const { totalCount: reportsHelmetTotalCount } = useAppSelector(state => state.reportsHelmet);
  const { totalCount: reportsShiftsTotalCount } = useAppSelector(state => state.reportsShifts);
  let { columns: columns } = useAppSelector(state => state.reportsColumns);

  if (!companyOptions.use_schedule_template) {
    columns = columns.filter(column => column.name !== 'working_shift');
  }
  const findCheck = (name: string): number[] => {
    const filter = reportsFilters.find(filter => filter.name === name);
    if (!filter) return [];
    return filter.data.filter(data => data.check).map(data => data.id);
  };

  const [tab, setTab] = React.useState('tasks');
  const [limit, setLimit] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState('');
  const [filterDay, setFilterDay] = React.useState(
    today.map((date: number) => moment(moment.unix(date).format(momentFormat))),
  );

  const tasksDefaultFilters = React.useMemo(() => {
    switch (tab) {
      case 'tasks':
        const tasksFilters = [
          {
            title: 'Цех',
            name: 'department',
            data: userRoleCanEdit ? dataDepartments : dataDepartmentsView,
          },
        ];

        if (companyOptions.use_schedule_template) {
          tasksFilters.push({
            title: 'Тип смены',
            name: 'shifts',
            data: dataWorkingShifts,
          });
        }

        return [
          ...tasksFilters,
          {
            title: 'Профессия',
            name: 'profession',
            data: dataProfessions,
          },
          {
            title: 'Исполнитель',
            name: 'users',
            data: users,
          },
          {
            title: 'Автор',
            name: 'author',
            data: users,
          },
          {
            title: 'Статус задачи',
            name: 'task_status',
            data: dataTaskStatuses,
          },
          {
            title: 'Тип задачи',
            name: 'task_type',
            data: dataTaskTypes,
          },
          {
            title: 'Вид задачи',
            name: 'task_class_id',
            radio: 'task_class_id',
            data: [
              { id: -1, name: 'все задачи', check: true },
              { id: 0, name: 'без времени', check: false },
              { id: 1, name: 'со временем', check: false },
            ],
          },
          {
            title: 'Зоны гео',
            name: 'geo_zones',
            data: dataZones,
          },
          {
            title: 'Статус ГЕО',
            name: 'geo_status',
            data: [
              { id: -1, name: 'Все', check: false },
              { id: 3, name: 'Был везде', check: false },
              { id: 2, name: 'Был не везде', check: false },
              { id: 1, name: 'Нигде не был', check: false },
            ],
          },
          {
            title: 'Статус НС',
            name: 'status_supervisor',
            data: [
              { id: 1, name: 'Выполнено', check: false },
              { id: 0, name: 'Отклонено', check: false },
              { id: -1, name: 'Не проверялось', check: false },
            ],
          },
          {
            title: 'Статус НУ',
            name: 'status_department',
            data: [
              { id: 1, name: 'Выполнено', check: false },
              { id: 0, name: 'Отклонено', check: false },
              { id: -1, name: 'Не проверялось', check: false },
            ],
          },
        ];
      case 'helmet':
        return [
          {
            title: 'Профессия',
            name: 'profession',
            data: dataProfessions,
          },
          {
            title: 'Разряд',
            name: 'category',
            data: [
              { id: 1, name: '1', check: false },
              { id: 2, name: '2', check: false },
              { id: 3, name: '3', check: false },
              { id: 4, name: '4', check: false },
              { id: 5, name: '5', check: false },
              { id: 6, name: '6', check: false },
            ],
          },
          {
            title: 'Цех',
            name: 'department',
            data: userRoleCanEdit ? dataDepartments : dataDepartmentsView,
          },
          {
            title: 'Статус работника',
            name: 'status',
            data: [
              { id: 1, name: 'В работе', check: false },
              { id: 2, name: 'Выполнено', check: false },
              { id: 3, name: 'Отклонено', check: false },
              { id: 4, name: 'На паузе', check: false },
            ],
          },
          {
            title: 'Статус НС',
            name: 'status_supervisor',
            data: [
              { id: 1, name: 'Выполнено', check: false },
              { id: 0, name: 'Отклонено', check: false },
              { id: -1, name: 'Не проверялось', check: false },
            ],
          },
          {
            title: 'Статус НУ',
            name: 'status_department',
            data: [
              { id: 1, name: 'Выполнено', check: false },
              { id: 0, name: 'Отклонено', check: false },
              { id: -1, name: 'Не проверялось', check: false },
            ],
          },
        ];
      case 'shifts':
        const shiftsFilters = [
          {
            title: 'Цех',
            name: 'department',
            data: userRoleCanEdit ? dataDepartments : dataDepartmentsView,
          },
        ];

        if (companyOptions.use_schedule_template) {
          shiftsFilters.push({
            title: 'Тип смены',
            name: 'shifts',
            data: dataWorkingShifts,
          });
        }

        return [
          ...shiftsFilters,
          {
            title: 'Профессия',
            name: 'profession',
            data: dataProfessions,
          },
          {
            title: 'Исполнитель',
            name: 'users',
            data: users,
          },
        ];
      case 'incidents':
        return [
          {
            title: 'Цех',
            name: 'department',
            data: userRoleCanEdit ? dataDepartments : dataDepartmentsView,
          },
          {
            title: 'Исполнитель',
            name: 'users',
            data: users,
          },
          {
            title: 'Профессия',
            name: 'profession',
            data: dataProfessions,
          },
          {
            title: 'Тип происшествия',
            name: 'type',
            data: DATA_INCIDENTS_TYPES,
          },
        ];
      case 'ppe':
        return [
          {
            title: 'Цех',
            name: 'department',
            data: userRoleCanEdit ? dataDepartments : dataDepartmentsView,
          },
          {
            title: 'Исполнитель',
            name: 'users',
            data: users,
          },
          {
            title: 'Профессия',
            name: 'profession',
            data: dataProfessions,
          },
          {
            title: 'Ношение каски',
            name: 'always_in_helmet',
            data: [
              { id: 1, name: 'Постоянно', check: false },
              { id: 2, name: 'Не постоянно', check: false },
            ],
          },
        ];
      case 'pos':
        return [
          {
            title: 'Цех',
            name: 'department',
            data: userRoleCanEdit ? dataDepartments : dataDepartmentsView,
          },
        ];
    }
  }, [dataProfessions, dataDepartments, tab, JSON.stringify(dataDepartmentsView)]);

  const handleSaveReports = () => {
    switch (tab) {
      case 'tasks':
      default:
        dispatchTasks(true);
        break;
      case 'helmet':
        dispatchHelmet(true);
        break;
      case 'shifts':
        dispatchShifts(true);
        break;
      case 'incidents':
        dispatchIncidents(true);
        break;
      case 'ppe':
        dispatchPPE(true);
        break;
      case 'pos':
        dispatchPOS(true);
        break;
    }
  };

  React.useEffect(() => {
    if (tasksDefaultFilters) {
      const filters = tasksDefaultFilters.filter(item =>
        !companyOptions.use_schedule_template ? item.name !== 'shifts' : true,
      );
      dispatch(setReportsFilters(filters));
    }
  }, [tasksDefaultFilters]);

  React.useEffect(() => {
    if (companyOptions?.use_geo) {
      dispatch(updateColumns('tasks-geo'));
    }
  }, [companyOptions?.use_geo]);

  const dispatchTasks = (excel: boolean) => {
    const dates = getDatesIsSame(filterDay[0], filterDay[1], companyOptions.time_zone);
    const input = {
      begin_date: dates.start_date,
      end_date: dates.end_date,
      search: search,
      departments_ids: findCheck(TYPE_ROLE.DEPARTMENT) as number[],
      working_shifts_ids: findCheck('shifts') as number[],
      professions_ids: findCheck('profession') as number[],
      users_ids: findCheck('users') as number[],
      authors_ids: findCheck('author') as number[],
      statuses_ids: findCheck('task_status') as number[],
      task_types_ids: findCheck('task_type') as number[],
      geo_zones_ids: findCheck('geo_zones') as number[],
      geo_statuses_ids: findCheck('geo_status') as number[],
      supervisor_statuses_ids: findCheck('status_supervisor') as number[],
      department_statuses_ids: findCheck('status_department') as number[],
      columns: columns.filter(column => column.check).map(column => column.name) as string[],
      task_class_id: findCheck('task_class_id')[0] ?? null,
      excel: excel,
      limit: Number(limit),
      page: Number(page) || 1,
    };

    if (excel) {
      Api.getTasksReportExport(input).then((response: any) => {
        if (response.kind === 'ok') {
          fileDownload(
            response.data,
            //TODO : type file FIX
            iconv.decode(`${response.data.filename}`, 'utf-8'),
          );
        }
        errorHandler(response);
      });
    } else {
      dispatch(fetchTasksReport(input));
    }
  };

  const dispatchHelmet = (excel: boolean) => {
    const input = {
      begin_date: prepareDateServerTime(
        filterDay[0].utc(true).format(FORMAT_MOMENT.DASH_YYYYMMDD),
        companyOptions.time_zone,
      ),
      end_date: prepareDateServerTime(
        filterDay[1].utc(true).format(FORMAT_MOMENT.DASH_YYYYMMDD),
        companyOptions.time_zone,
      ),
      columns: columns.filter(column => column.check).map(column => column.name),
      department: findCheck(TYPE_ROLE.DEPARTMENT) as number[],
      search,
      excel,
      fixed: findCheck('fixed') as number[],
      limit: Number(limit),
      page: Number(page) || 1,
    };

    if (excel) {
      Api.getHelmetReportExport(input).then((response: any) => {
        if (response.kind === 'ok') {
          fileDownload(response.data, iconv.decode(`${response.data.filename}`, 'utf-8'));
        }
        errorHandler(response);
      });
    } else {
      dispatch(fetchHelmetReport(input));
    }
  };

  const dispatchShifts = (excel: boolean) => {
    const input = {
      begin_date: prepareDateServerTime(
        filterDay[0].utc(true).format(FORMAT_MOMENT.DASH_YYYYMMDD),
        companyOptions.time_zone,
      ),
      end_date: prepareDateServerTime(
        filterDay[1].utc(true).format(FORMAT_MOMENT.DASH_YYYYMMDD),
        companyOptions.time_zone,
      ),
      search: search,
      departments_ids: findCheck(TYPE_ROLE.DEPARTMENT) as number[],
      working_shifts_ids: findCheck('shifts') as number[],
      professions_ids: findCheck('profession') as number[],
      users_ids: findCheck('users') as number[],
      columns: columns.filter(column => column.check).map(column => column.name),
      excel,
      limit: Number(limit),
      page: Number(page) || 1,
    };

    if (excel) {
      Api.getShiftsReportExport(input).then((response: any) => {
        if (response.kind === 'ok') {
          fileDownload(response.data, iconv.decode(`${response.data.filename}`, 'utf-8'));
        }
        errorHandler(response);
      });
    } else {
      dispatch(fetchShiftsReport(input));
    }
  };

  const dispatchIncidents = (excel: boolean) => {
    const input: IIncidentsListFilter = {
      begin_date: prepareDateServerTime(
        filterDay[0].utc(true).format(FORMAT_MOMENT.DASH_YYYYMMDD),
        companyOptions.time_zone,
      ),
      end_date: prepareDateServerTime(
        filterDay[1].utc(true).format(FORMAT_MOMENT.DASH_YYYYMMDD),
        companyOptions.time_zone,
      ),
      search: search,
      departments_id: findCheck(TYPE_ROLE.DEPARTMENT) as number[],
      professions_id: findCheck('profession') as number[],
      user_id: findCheck('users') as number[],
      categories_id: findCheck('categories') as number[],
      type: findCheck('type')
        .map(checkId => {
          return DATA_INCIDENTS_TYPES.find(item => item.id === checkId)?.slug;
        })
        .filter(item => item !== undefined) as EIncidentType[],
      limit: Number(limit),
      offset: Number((page - 1) * limit) || 0,
    };

    if (excel) {
      // Api.getIncidentsReportExport(input).then((response: any) => {
      //   if (response.kind === 'ok') {
      //     fileDownload(response.data, iconv.decode(`${response.data.filename}`, 'utf-8'));
      //   }
      //   errorHandler(response);
      // });
    } else {
      dispatch(fetchIncidentsList(input));
    }
  };

  const dispatchPPE = (excel: boolean) => {
    const dates = getDatesIsSame(
      filterDay[0].utc(true),
      filterDay[1].utc(true),
      companyOptions.time_zone,
    );
    const input: IZoneEntranceDataFilter = {
      begin_date: dates.start_date,
      end_date: dates.end_date,
      search: search,
      departments_ids: findCheck(TYPE_ROLE.DEPARTMENT) as number[],
      professions_ids: findCheck('profession') as number[],
      users_ids: findCheck('users') as number[],
      always_in_helmet: findCheck('always_in_helmet').find(item => item === 1) !== undefined,
      limit: Number(limit),
      offset: Number((page - 1) * limit) || 0,
    };

    if (excel) {
      // Api.getZoneEntranceDataReport(input).then((response: any) => {
      //   if (response.kind === 'ok') {
      //     fileDownload(response.data, iconv.decode(`${response.data.filename}`, 'utf-8'));
      //   }
      //   errorHandler(response);
      // });
    } else {
      dispatch(fetchZoneEntranceReports(input));
    }
  };

  const dispatchPOS = (excel: boolean) => {
    const dates = getDatesIsSame(
      filterDay[0].utc(true),
      filterDay[1].utc(true),
      companyOptions.time_zone,
    );
    const input: IWorkRequestsFilter = {
      created_start_date: dates.start_date,
      created_end_date: dates.end_date,
      search: search,
      departments_ids: findCheck(TYPE_ROLE.DEPARTMENT) as number[],
      limit: Number(limit),
      offset: Number((page - 1) * limit) || 0,
      excel: excel,
    };

    if (excel) {
      Api.getWorkRequestsReportExport(input).then((response: any) => {
        if (response.kind === 'ok') {
          fileDownload(response.data, iconv.decode(`${response.data.filename}`, 'utf-8'));
        }
        errorHandler(response);
      });
    } else {
      dispatch(fetchWorkRequestsReport(input));
    }
  };

  const onlyChecked = (reportsFilters: any[]) => {
    const activeFilters: any[] = [];
    reportsFilters.forEach(item => {
      item.data.forEach((subItem: { check: any; name: any }) => {
        if (subItem.check) activeFilters.push(item.name + ':' + subItem.name);
      });
    });
    return JSON.stringify(activeFilters);
  };

  React.useEffect(() => {
    if (findCheck('department').length || findCheck('profession').length) {
      const userFilters = users.filter(item => {
        let checkDep = false;
        findCheck('department').forEach(dep => {
          if (item.department_ids.includes(dep!)) {
            checkDep = true;
          }
        });

        if (checkDep) {
          return item;
        }
      });

      dispatch(
        setReportsFilterItem({
          title: 'Исполнитель',
          name: 'users',
          data: userFilters.filter(item => {
            let checkProf = false;

            if (findCheck('profession').length) {
              findCheck('profession').forEach(prof => {
                if (item.profession_id === prof) {
                  checkProf = true;
                }
              });
            } else {
              checkProf = true;
            }

            if (checkProf) {
              return item;
            }
          }),
        }),
      );
      dispatch(
        setReportsFilterItem({
          title: 'Автор',
          name: 'author',
          data: userFilters.filter(item => {
            let checkProf = false;

            if (findCheck('profession').length) {
              findCheck('profession').forEach(prof => {
                if (item.profession_id === prof) {
                  checkProf = true;
                }
              });
            } else {
              checkProf = true;
            }

            if (checkProf) {
              return item;
            }
          }),
        }),
      );
    } else {
      dispatch(
        setReportsFilterItem({
          title: 'Автор',
          name: 'author',
          data: users,
        }),
      );
      dispatch(
        setReportsFilterItem({
          title: 'Исполнитель',
          name: 'users',
          data: users,
        }),
      );
    }
  }, [JSON.stringify(findCheck('department')), JSON.stringify(findCheck('profession'))]);

  React.useEffect(() => {
    switch (tab) {
      case 'tasks':
      default:
        dispatchTasks(false);
        break;
      case 'helmet':
        dispatchHelmet(false);
        break;
      case 'shifts':
        dispatchShifts(false);
        break;
      case 'incidents':
        dispatchIncidents(false);
        break;
      case 'ppe':
        dispatchPPE(false);
        break;
      case 'pos':
        dispatchPOS(false);
        break;
    }
  }, [tab, filterDay, limit, page, search, onlyChecked(reportsFilters)]);

  const handelTab = (name: string) => {
    switch (name) {
      case 'tasks':
        dispatch(updateColumns(companyOptions?.use_geo ? 'tasks-geo' : 'tasks'));
        setTab('tasks');
        break;
      case 'helmet':
        dispatch(updateColumns('helmet'));
        setTab('helmet');
        break;
      case 'shifts':
        dispatch(updateColumns('shifts'));
        setTab('shifts');
        break;
      case 'incidents':
        dispatch(updateColumns('incidents'));
        setTab('incidents');
        break;
      case 'ppe':
        dispatch(updateColumns('ppe'));
        setTab('ppe');
        break;
      case 'pos':
        dispatch(updateColumns('pos'));
        setTab('pos');
        break;
    }
  };

  return (
    <div className="layout-screen">
      <h1 className="layout-screen__title">{titlesMap.reports}</h1>
      <div className="tabs layout-screen__tabs">
        <div
          className={`tabs__tab ${tab === 'shifts' ? 'active' : ''}`}
          onClick={() => handelTab('shifts')}
        >
          Смены
        </div>
        <div
          className={`tabs__tab ${tab === 'tasks' ? 'active' : ''}`}
          onClick={() => handelTab('tasks')}
        >
          Задачи
        </div>
        {companyOptions?.use_helmet && (
          <div
            className={`tabs__tab ${tab === 'helmet' ? 'active' : ''}`}
            onClick={() => handelTab('helmet')}
          >
            Нарушения ОТиПБ
          </div>
        )}
        {companyOptions?.use_geo && (
          <div
            className={`tabs__tab ${tab === 'ppe' ? 'active' : ''}`}
            onClick={() => handelTab('ppe')}
          >
            Ношение каски
          </div>
        )}

        <div
          className={`tabs__tab ${tab === 'incidents' ? 'active' : ''}`}
          onClick={() => handelTab('incidents')}
        >
          Происшествия
        </div>
        <div
          className={`tabs__tab ${tab === 'pos' ? 'active' : ''}`}
          onClick={() => handelTab('pos')}
        >
          ПОС
        </div>
      </div>

      <SearchInput
        onChange={setSearch}
        onEnter={setSearch}
        title="Введите ФИО, профессию или название задачи"
      />

      <div className="layout-screen__filter">
        <ReportsFilters filterDay={filterDay} setFilterDay={setFilterDay} />
        {!['incidents'].includes(tab) && (
          <Button
            type={BUTTON_TYPE.ICON}
            text="Скачать отчет"
            onClick={handleSaveReports}
            className="download"
            icon
            iconType={ICON_TYPE.DOWNLOAD}
            background={COLORS.BLUE}
          />
        )}
      </div>
      <Pagination
        className="layout-screen__paginationTop"
        setLimit={setLimit}
        limit={limit}
        page={page}
        setPage={setPage}
        totalCount={
          tab === 'tasks'
            ? reportsTaskTotalCount
            : tab === 'shifts'
            ? reportsShiftsTotalCount
            : reportsHelmetTotalCount
        }
      />
      <ReportsTable columns={columns} tab={tab} />
    </div>
  );
};

export default ReportsScreen;

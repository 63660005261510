import { ICustomField } from 'sb_manufacturing_front_api';
import { createEntityAdapter, createSlice, Dictionary, EntityId } from '@reduxjs/toolkit';

export const workRequestCustomFieldsAdapter = createEntityAdapter<ICustomField>({
  selectId: workRequestCustomFields => workRequestCustomFields.id,
});

export interface IWorkRequestCustomFieldsState {
  ids: EntityId[];
  entities: Dictionary<ICustomField>;
}
const workRequestCustomFieldsSlice = createSlice({
  name: 'workRequestCustomFields',
  initialState: workRequestCustomFieldsAdapter.getInitialState(),
  reducers: {
    create: workRequestCustomFieldsAdapter.setMany,
    update: workRequestCustomFieldsAdapter.upsertMany,
    remove: workRequestCustomFieldsAdapter.removeMany,
    clear: workRequestCustomFieldsAdapter.removeAll,
  },
});

export default workRequestCustomFieldsSlice.reducer;

export const { create, update, remove, clear } = workRequestCustomFieldsSlice.actions;

import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';
import { tasksAdapter } from '~/redux/modules/tasksModule';
import { selectCurrentShifts, selectShifts } from './shiftsSelectors';
import { selectDay, selectFilteredDepartmentIds } from './stateSelectors';
import moment from 'moment';
import { selectUsers } from './usersSelectors';
import { FORMAT_MOMENT } from '~/helpers/constants';
import containsArray from '~/helpers/containsArray';

export const tasksSelector = tasksAdapter.getSelectors<IStore>(state => state.task);

export const selectTasks = createSelector(
  (state: IStore) => tasksSelector.selectAll(state),
  task => task,
);

export const selectDayTasks = createSelector([selectTasks, selectDay], (tasks, day) => {
  return tasks.filter(task => moment(task.begin_time).format(FORMAT_MOMENT.DASH_YYYYMMDD) === day);
});

export const selectTasksShift = createSelector(
  [selectTasks, selectUsers, selectCurrentShifts, selectFilteredDepartmentIds],
  (tasks, usersList, shifts, departmentIds) => {
    const filteredShift = shifts.filter(shift => {
      const shiftUser = usersList.find(user => user.id === shift.user_id);
      return shiftUser && containsArray(departmentIds, shiftUser?.departments_ids || []);
    });
    return tasks.filter(task => {
      let taskInShift = false;
      filteredShift.forEach(shift => {
        if (
          shift.user_id === task.user_id &&
          (shift.id === task.shift_id || task.shift_id === null)
        ) {
          taskInShift = true;
        }
      });
      return taskInShift;
    });
  },
);

export const selectUserTasks = (userId: number) =>
  createSelector([selectTasks, selectDay, selectShifts], (tasks, day) => {
    return tasks.filter(task => {
      return (
        moment(task.begin_time).format(FORMAT_MOMENT.DASH_YYYYMMDD) === day &&
        task.user_id === userId
      );
    });
  });

export const selectUserTasksTimeless = (userId: number) =>
  createSelector([selectTasks, selectDay, selectShifts], tasks => {
    return tasks.filter(task => task?.user_id === userId && task.parent_id);
  });

export const selectTask = (taskId: number) =>
  createSelector([selectTasks, selectDay, selectShifts], tasks => {
    const index = tasks.findIndex(task => task.id === taskId);
    return tasks[index];
  });

import * as React from 'react';
import { MultiGrid, GridCellProps, AutoSizer, Index } from 'react-virtualized';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserIdsToShow } from '~/redux/selectors/stateSelectors';
import ItemUser from '~/components/schedule/ItemUser';
import Shift from '~/components/schedule/Shift';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { RenderedSection } from 'react-virtualized/dist/es/Grid';
import moment from 'moment';
import { getPeriodShifts } from '~/redux/modules/shiftsModule';
import { prepareDateServerTime } from '~/helpers/convertToUnix';
import { useUserFilter } from '~/hooks/useUserFilters';
import classNames from 'classnames';
import { FORMAT_MOMENT, tableClassName } from '~/helpers/constants';
import { selectUserRoleCanEdit } from '~/redux/selectors/authSelectors';
import { useRef } from 'react';

interface IFC {
  dates: string[];
  setDates: (value: string[]) => void;
  fio: string;
  useFilter: boolean;
  setUseFilter: (value: boolean) => void;
}

const Table = ({ dates, setDates, fio, useFilter, setUseFilter }: IFC) => {
  const dispatch = useDispatch();
  const userIdsToShow = useSelector(selectUserIdsToShow);
  const timeZone = useSelector(selectCompanyOptions).time_zone;
  const userRoleCanEdit = useSelector(selectUserRoleCanEdit);
  const tableRef = useRef<HTMLDivElement>(null);

  const { categories_ids, departments_ids, professions_ids } = useUserFilter();
  const [isFetching, setIsFetching] = React.useState(false);
  const [indexColumn, setIndexColumn] = React.useState(29);
  const [currentDate, setCurrentDate] = React.useState<string>('');
  const [selectedColumn, setSelectedColumn] = React.useState<string>(
    moment().format(FORMAT_MOMENT.DASH_YYYYMMDD),
  );
  const dateMoment = moment();

  const cellRenderer = ({ columnIndex, key, rowIndex, style }: GridCellProps) => {
    const userId = userIdsToShow[rowIndex - 1];
    const date = dates[columnIndex];
    const momentDate = moment(date);
    const isBefore = moment(date).isBefore(moment().subtract(1, 'day'));

    const render = () => {
      switch (columnIndex) {
        case 0:
          switch (rowIndex) {
            case 0:
              return <h3 className={'currentDate'}>{currentDate}</h3>;
            default:
              return (
                <ItemUser
                  userId={userId}
                  key={rowIndex}
                  edit={userRoleCanEdit}
                  tableRef={tableRef}
                />
              );
          }
        default:
          switch (rowIndex) {
            case 0:
              return (
                <div
                  className={classNames('scrollable-column__date', {
                    ['isToday']: selectedColumn === date,
                  })}
                  onClick={() => setSelectedColumn(date)}
                >
                  <span className="day">{momentDate.format(FORMAT_MOMENT.D)}</span>
                  <span className="day-week">{momentDate.format('ddd')}</span>
                </div>
              );
            default:
              return (
                <Shift
                  dateMoment={dateMoment}
                  timeZone={timeZone}
                  userId={userId}
                  date={date}
                  isBefore={isBefore}
                  rowIndex={rowIndex}
                  rowLength={userIdsToShow.length}
                />
              );
          }
      }
    };

    return (
      <div key={key} style={style}>
        <div
          className={classNames('table-cell', {
            ['isToday']: selectedColumn === date,
          })}
        >
          {render()}
        </div>
      </div>
    );
  };

  const getColumnWidth = ({ index }: Index) => {
    switch (index) {
      case 0:
        return 500;
      default:
        return 52;
    }
  };

  const overScanForLoad = 30;
  const amountLoad = 30;

  React.useEffect(() => {
    setIsFetching(false);
  }, [dates.length]);

  const loadPrevDate = (columnStartIndex: number) => {
    setIsFetching(true);
    const array = [...dates];
    const dateEnd = array[0];

    dispatch(
      getPeriodShifts({
        data: {
          start_time: prepareDateServerTime(dateEnd, timeZone),
          end_time: prepareDateServerTime(
            moment(dateEnd).add(amountLoad, 'day').toString(),
            timeZone,
          ),
          categories_ids,
          departments_ids,
          fio,
          professions_ids,
        },
        timeZone,
      }),
    );

    for (let i = 1; i < amountLoad + 1; i++) {
      array.unshift(moment(dateEnd).subtract(i, 'day').format(FORMAT_MOMENT.DASH_YYYYMMDD));
    }

    setDates(array);
    setIndexColumn(columnStartIndex + amountLoad + 2);
  };

  const loadNextDate = () => {
    setIsFetching(true);
    const array = [...dates];
    const dateEnd = array[array.length - 1];

    dispatch(
      getPeriodShifts({
        data: {
          start_time: prepareDateServerTime(dateEnd, timeZone),
          end_time: prepareDateServerTime(
            moment(dateEnd).add(amountLoad, 'day').toString(),
            timeZone,
          ),
          categories_ids,
          departments_ids,
          fio,
          professions_ids,
        },
        timeZone,
      }),
    );

    for (let i = 1; i < amountLoad + 1; i++) {
      array.push(moment(dateEnd).add(i, 'day').format(FORMAT_MOMENT.DASH_YYYYMMDD));
    }

    setDates(array);
  };

  const onSectionRendered = ({ columnStartIndex, columnStopIndex }: RenderedSection) => {
    if (isFetching) return;

    setCurrentDate(
      moment(dates[Math.floor((columnStartIndex + columnStopIndex) / 2)]).format('MMMM YYYY'),
    );

    columnStartIndex <= overScanForLoad && !useFilter && loadPrevDate(columnStartIndex);
    columnStopIndex >= dates.length - overScanForLoad && !useFilter && loadNextDate();
  };

  return (
    <div className={tableClassName} ref={tableRef}>
      <AutoSizer>
        {({ width, height }) => (
          <MultiGrid
            onSectionRendered={onSectionRendered}
            scrollToColumn={indexColumn}
            scrollToAlignment={'start'}
            cellRenderer={cellRenderer}
            onScroll={() => setUseFilter(false)}
            columnCount={dates.length}
            columnWidth={getColumnWidth}
            height={height}
            rowCount={userIdsToShow.length + 1}
            rowHeight={56}
            width={width}
            fixedColumnCount={1}
            fixedRowCount={1}
            className={'scrollable-table'}
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default Table;

declare global {
  interface IAlert {
    isVisible: boolean;
    data: IAlertData;
  }

  interface IAlertData {
    title: string;
    description: string;
    name: string;
    type: string;
    data?: {
      userId?: number;
      taskId?: number;
      shiftId?: number;
      userName?: string;
      startTime?: string;
      workShiftId?: number;
      isDuration?: boolean;
      taskStatus?: number;
      taskComment?: string;
      taskImages?: string[];
      shiftData?: IStateForm;
    };
  }

  interface IShiftTracking {
    user_id: number;
    begin_time: string | undefined;
    end_time: string | undefined;
  }

  interface IColumnFilter {
    title: string;
    name: string;
    data: IFilterData[];
    radio?: string;
  }

  interface IFilterData {
    id: number;
    name: string;
    check: boolean;
    deleted?: boolean;
  }
}

export default global;

export enum EWorkRequestType {
  E_TOIR = 0,
  E_POS = 1,
}
